import React, { useState, useEffect, useRef } from 'react'
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import { object, string, number } from 'yup';
import { Form as form, Formik, Field, useFormik } from "formik";
import country from './country';
import URL from '../../apiurl';
//import PhoneInput from 'react-phone-input-2';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Modal from 'react-bootstrap/Modal';
import { useForm, Controller } from 'react-hook-form';
import { ErrIcon, Thankyou } from '../../assets/images';
import { Loader } from '../../assets/images';
import { Icon } from '../../assets/images';
import Success_popup from '../success_popup/Success_popup';

const Formlabel = styled.label`
     font-size: min(max(14px, calc(0.875rem + (16 - 14) * ((100vw - 320px) / (1400 - 320)))), 16px);
     font-family: 'Inter';
     font-weight: 700;
     color: #5F5F5F;
     margin-bottom: 5px;
`
const Description = styled.p`
     font-size: min(max(14px, calc(0.875rem + (16 - 14) * ((100vw - 320px) / (1400 - 320)))), 16px);
     font-family: 'Inter';
     font-weight: 400;
     color: #000;
     margin-bottom: 5px;
`
const Button = styled.button`
    
    background: linear-gradient(90deg, rgba(243,154,52,1) 0%, rgba(252,199,58,1) 50%);
    color: #0E0A00;
    font-family: 'Open Sans',sans-serif;
    font-weight: 700;
    border: none;
    padding: 8px 40px;
    font-size: min(max(14px, calc(0.875rem + (20 - 14) * ((100vw - 320px) / (1400 - 320)))), 20px);
    border-radius: 5px;
    margin-top: 16px;
    &:hover{
      background: #000  !important;
      color:#fff !important;
    }
`
 


const KickStartForm = () => {
const [validated, setValidated] = useState(false);
const[productData, setProductData] = useState([])
const[company, setCompany] = useState(false)
const[showed, setShowed] = useState(false)
const[mobile, setMobile] = useState('');
const[stylePhone, setstylePhone] = useState({})
const [selectedCountry, setSelectedCountry] = useState('in');
const [isSubmitting, setIsSubmitting] = useState(false);
//const formRef = useRef();
const[companyVal, setCompanyVal] = useState()
const handleCloseSecond = () => setShowed(false);

  const {
    handleSubmit,
    control,
    register,
    watch,
    formState: { errors },
    setValue,
    getValues,
    trigger, // Include the trigger function
    reset,
  } = useForm();
  const selectedOption = watch('entity');
  

  



  const getProductData = async () => {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${token}`
      }
    }

    await fetch(`${URL}/get/product`, options)
      .then(res => res.json())
      .then(res => {
        //console.log(res)
        setProductData(res.data)

      })
  }

  const initialValues = {
    name: '',
    email: '',
    mobile: '',
    entity: '',
    product: '',
    country: '',
    message: '',
    company: '',
  }

  const submitForm = async (data) => {
    setIsSubmitting(true);
    
    try {

      const bodyData = {
        "name": data?.name,
        "mobile": data?.mobile,
        "email": data?.email,
        "message": data?.message,
        "entity": data?.entity,
        "products": data?.product,
        "country": data?.country,
        "company": data?.company
      }

      //  (company)? bodyData.company = data.company : "";
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(bodyData)
      }
      await fetch(`${URL}/create/kickform`, options)
        .then(res => res.json())
        .then(res => {
          // Formik.resetForm()
          // console.log(res)
          setIsSubmitting(false);
          reset(initialValues)
          setCompanyVal('')
          setCompany(false)
          setSelectedCountry('in');
          setShowed(true)
         

        })
    } catch (error) {
      console.log(error)
      setIsSubmitting(false);

    }
  }




  const logValidationStatus = () => {
    const formValues = getValues();
    const validationStatus = {};

    Object.keys(formValues).forEach((fieldName) => {
      validationStatus[fieldName] = !!errors[fieldName];
    });

    console.log('Form Values:', formValues);
    console.log('Validation Status:', validationStatus);
  };
  //src="/static/media/getintouch-img.8d494119f8e5e629032c.png"
  
const err = {
  width: '100%',
  height: '50px', 
  border: '1px solid #dc3545',
  //backgroundImage: `url(${ErrIcon})`,
  backgroundImage: 'url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e")',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right calc(0.375em + 0.1875rem) center',
  backgroundSize: 'calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)',
}

const noerr = {
  width: '100%',  height: '50px', 
}
       
  useEffect(() => {
    getProductData()

  }, [])
  //console.log(productData, company)
  return (
    <>
      {/**        <form  ref={formRef} 
    onSubmit={formik.handleSubmit} 
    validated={validated}  >
      */}                               
                 <form onSubmit={handleSubmit(submitForm)}>                      
   
       


        <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
          <Formlabel>Full Name <span className='text-danger'>*</span></Formlabel>
          <Form.Control
            name="name"
            type="text"
            placeholder="Full name "
            error={!!errors.name}

            {...register('name', {
              
              validate: (value) => {
                return !!value.trim() || 'Please provide the full name';
            },required: 'Please provide the full name',
            }
            
            )}
            //  onChange={formik.handleChange}
            isInvalid={!!errors.name}
          />
          <Form.Control.Feedback type="invalid"> {errors.name?.message}  </Form.Control.Feedback>
        </Form.Group>



                                       
       
      
 

<Form.Group className="mb-4" controlId="exampleForm.ControlInput2">
        <Formlabel>Email Id <span className='text-danger'>*</span></Formlabel>
        <Form.Control 
        name="email"
        type="email" 
        placeholder="Email Id " 
        error={!!errors.email}
       
        {...register('email', {
          required: 'Please provide the email id',
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: 'Please provide the valid email id',
          },
        })}
      //  onChange={formik.handleChange}
        isInvalid={!!errors.email}  
        />
        <Form.Control.Feedback type="invalid">   {errors.email?.message}  </Form.Control.Feedback>
      </Form.Group>
 

 
  
      <div className='mb-4'>
 <Formlabel>Mobile Number <span className='text-danger'>*</span></Formlabel>
 


<Controller
          name="mobile"
          control={control}
          defaultValue=""
          rules={{ 
            validate: (value) => {
              return !!value.trim() || 'Please provide the mobile number ';
          },
            required: 'Please provide the mobile number ' }}
          render={({ field }) => (  
            
            <PhoneInput
            inputStyle={errors.mobile?err:noerr} 
             
             country={selectedCountry}
             placeholder="Mobile Number *"
             value={mobile}
             {...field}
             onChange={(value)=>{
              setMobile(value)
              field.onChange(value);
              console.log('on Change happened', value)
             }}
             onBlur={(value, country)=>{
              setSelectedCountry(country.countryCode);
              setMobile('')
              field.onBlur(value);
              console.log('on Blur happened', country)
               }}
               
            >
           </PhoneInput>
           
           )}
        /> 
        <span style={{ fontSize:  '.875em'}} className="text-danger">{errors.mobile?.message} </span>
        </div>

     
      <Formlabel>Entity <span className='text-danger'>*</span></Formlabel>
     <div>
 
     <div key='inline-radio' >
             <Form.Group className="mb-4 " controlId="entity"> 
             <Col>
             <label htmlFor="individual"> 
          <Form.Check
          inline 

          name="entity"
          id="individual"
            type='radio'
            
            value="individual"
            {...register('entity', {
              required: 'Please choose any one entity' }
              )}
            onClick={()=>{
              setCompany(false)
              setValue('company', '');
              //trigger('company')
            }}
            isInvalid={!!errors.entity} 
                 
          /> Individual</label>
  <label style={{paddingLeft:'18px'}}  htmlFor="company">
                <Form.Check
                  inline
                  name="entity"
                  id="company"
                  type='radio'
                 
                  value="company"
                  {...register('entity', {
                    required: 'Please choose any one entity'
                  }
                  )}
                  onClick={() => {
                   // trigger('company')
                    setCompany(true)
                  }}
                  isInvalid={!!errors.entity}

                  /> 
Company</label>

              </Col>
              <span style={{ fontSize:  '.875em'}} className="text-danger">{errors.entity?.message} </span>
            </Form.Group>

          </div>
         

        </div>

        <Form.Control.Feedback type="invalid">   {errors.entity?.message}  </Form.Control.Feedback>
        

        {(company) ?

          <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
            <Formlabel>Company Name <span className='text-danger'>*</span></Formlabel>
            <Form.Control
              name="company"
              type="text"
              placeholder="Company name"
              {...register('company', {
                required: 'Please provide the company name'
              })}
              //value={companyVal}
              onChange={(e) => {trigger('company');
                setValue('company', e.target.value);
              }}
              
              isInvalid={!!errors.company}
            />
            <Form.Control.Feedback type="invalid">   {errors.company?.message}  </Form.Control.Feedback>
          </Form.Group>

          : ""}





        <Form.Group className="mb-4" controlId="exampleForm.ControlInput4">
          <Formlabel>Select Country <span className='text-danger'>*</span></Formlabel>
          <Form.Select aria-label="Default select example"
            name="country"
            {...register('country', {
              required: 'Please select the country'
            })}
            isInvalid={!!errors.country}
          >
            <option value="" selected disabled>Select Country</option>
            {
              country.map((item, index) => {
                return <option key={index} value={item.code} > {item.country} </option>
              }
              )}

          </Form.Select>
          <Form.Control.Feedback type="invalid">   {errors.country?.message}  </Form.Control.Feedback>
        </Form.Group>


        <Form.Group className="mb-4" controlId="exampleForm.ControlInput4">
          <Formlabel>Select Products <span className='text-danger'>*</span></Formlabel>
          <Form.Select aria-label="Default select example"
            name="product"
            {...register('product', {
              required: 'Please select the products'
            })}
            isInvalid={!!errors.product}
          >
            <option value="" selected disabled>Select Products</option>
            {productData.map((item, index) => {
              return (
                <option value={item.product_id}>{item.product_name}</option>

              )
            })}

          </Form.Select>
          <Form.Control.Feedback type="invalid">   {errors.product?.message}  </Form.Control.Feedback>
        </Form.Group>



        <Form.Group className="mb-4" controlId="exampleForm.ControlTextarea1">
          <Formlabel>How can we help?</Formlabel>
          <Form.Control as="textarea" name="message" {...register('message')} placeholder='Type what you want' rows={6} />
          <Form.Control.Feedback type="invalid">      </Form.Control.Feedback>
        </Form.Group>


        <Row>
          <Col lg={10}>
            <Description>By submitting this form, you consent to Accupayd <Link to={'/TermofUse'}> terms of use & privacy policy.</Link> Data will not be shared with third-parties for commercial purposes.</Description>
            <Button type="submit" onClick={logValidationStatus}>{isSubmitting ? 'Submitting...' : 'Submit'}</Button>
            {isSubmitting ? <img className='img-fluid loader' src={Loader} alt="loading" /> : '' }


          </Col>
        </Row>




      </form>
      






      <Success_popup show={showed} onHide={handleCloseSecond}/>

  

    </>
  )
}

export default KickStartForm