export const LOGO = require('./Logo.png')
export const BANNERBG = require('./banner_bg.png')
export const ANIMATIONBANNER_1 = require('./animation_img_1.png')
export const ANIMATIONBANNER_2 = require('./animation_img_2.png')
export const CARDINS1 = require('./card_Inss_1.png')
export const CARDINS2 = require('./card_Inss_2.png')
export const CARDINS3 = require('./card_Inss_3.png')
export const CARDINS4 = require('./card_Inss_4.png')
export const CARDINS5 = require('./card_Inss_5.png')
export const CARDINS6 = require('./card_Inss_6.png')
export const PAYMENT1 = require('./payment_card_1.png')
export const PAYMENT2= require('./payment_card_2.png')
export const PAYMENT3 = require('./payment_card_3.png')
export const PAYMENT4 = require('./payment_card_4.png')
export const PAYMENT5 = require('./payment_card_5.png')
export const PAYMENT6= require('./payment_card_6.png')
export const CARDINSSUANCEIMG= require('./card_issuance_page_img.png')
export const COMMONBANNERBG= require('./comom_banner_bg.png')
export const BANKINGACBG= require('./banking_acc_bg.png')
export const PAYMENTACBG= require('./accoridan_payment.png');
export const COPRATECARDSBG= require('./coprate_cards.png')
export const MULTICURRENCYCARDBANNERBG= require('./multicurrencycardbannerbg.png');
export const GIFTCARDBG= require('./giftcardbg.png');
export const CPRCARDBG= require('./cprcardbg.png');
export const COPRATECARD = require('./copratecard.png');
export const MOBILITYCARDBG = require('./mobility-cards-bg.png');
export const VIRTUALCARDBG = require('./virtual-card-bg.png');
export const AADHAARBG = require('./adhaar-bg.png');
export const PREPAIDBG = require('./prepaid-bg.png');
export const CREDICARDBILLSBG = require('./creditcard-bg.png');
export const UTILITYBILLS = require('./utility-bg.png');
export const PAYRENTBG = require('./pay-rent-bg.png');
export const MOBILEDTHBG = require('./dth-bg.png');
export const BANKING_1 = require('./bankin_1.png')
export const BANKING_2 = require('./bankin_2.png')
export const BANKING_3 = require('./banking_3.png')
export const BANKING_4 = require('./banking_4.png')
export const BANKING_5 = require('./bankin_5.png')
export const BANKING_6 = require('./banking_6.png')
export const READINESS = require('./readiness.png')
export const TRUST = require('./trust.png')
export const VERSATILITY = require('./versatility.png')
export const TAILOR = require('./tailor.png')
export const IAS_1 = require('./ias-1.png')
export const IAS_2 = require('./ias-2.png')
export const IAS_3 = require('./ias-3.png')
export const Portrait_man = require('./portrait-man.png')
export const Getintouch = require('./getintouch-img.png')
export const Banner_bg = require('./bg-banner.png')
export const Service_img = require('./handsome-boy.png')
export const Scroll = require('./scroll.gif')
export const Service_img2 = require('./banking.png')

export const Service_img3 = require('./payment.png')
export const Service_img9 = require('./safegold.png')
export const Service_img8 = require('./neobanking.png')
export const Service_img7 = require('./prepaid.png')
export const Service_img5 = require('./emt.png')
export const Service_img4 = require('./atm.png')
export const Service_img6 = require('./bill-payment.png')
export const Service_img10 = require('./bill.png')
export const Thankyou = require('./thank_you_logo.gif')
export const Team_1 = require('./team_1.png')
export const Team_2 = require('./team_2.png')
export const Team_3 = require('./team_3.png')
export const Team_4 = require('./team_4.png')
export const Team_5 = require('./team_5.png')
export const Team_6 = require('./team_6.png')
export const Who_we_are = require('./who-we-are.png')
export const About_img = require('./about.png')
export const Loader = require('./loader.gif')
export const ErrIcon = require('./icon.png')
export const PAYMENTSHOMEBANNER = require('./payement_home_banner.png')