import React, { useEffect } from 'react'
import PageBanner from '../../components/page-banner/PageBanner'
import { Service_img3 } from '../../assets/images'
import { SloveFor } from '../../components/slovefor/SloveCard'
import { WhyAccupayd } from '../../components/why-accupayd/whyAccupayd'
import GetinTouch from '../../components/get-in-touch/GetinTouch'
import ServicesCard from '../../components/servicecard/ServicesCard'
import PaymentAccord from '../../components/PaymentAccord/PaymentAccordian'
import FooterPaymentsServices from '../../components/footer-payments-services/FooterPaymentsServices'
import CommomBanner from '../../components/commonbanner/CommomBanner'

const PaymentServicesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <PageBanner
        breadcrumb={"Payment Services"}
        title={"Payment Services"}
        preLink= {'/payment-services'}
        content={"These services play a crucial role in modern commerce, enabling secure and efficient transactions between individuals, businesses, and other entities. There are various types of payment services available, catering to different needs and preferences. Some common payment services include:"}
        image={Service_img3} />
        <PaymentAccord />
        <SloveFor title={'We Solve For'}  routeName="Payment Services" content = "Industries across the board use our card solutions for diverse requirements. You name it, we've powered it!"/>
        <CommomBanner title={"Get the Payment Services for Your Needs"} />
        <WhyAccupayd title="Why Accupayd?" />
        <GetinTouch />
        <FooterPaymentsServices />
       
       
    </div>
  )
}

export default PaymentServicesPage
