import React, { useEffect } from 'react'
import PageBanner from '../../components/page-banner/PageBanner'
import { Service_img10 } from '../../assets/images'
import { SloveFor } from '../../components/slovefor/SloveCard'
import GetinTouch from '../../components/get-in-touch/GetinTouch'
import FooterBankingServices from '../../components/footer-banking-services/FooterBankingServices'
import CommomBanner from '../../components/commonbanner/CommomBanner'
import { FeaturedBenefits } from '../../components/featured-benefits/featured-benefits'

const BillPayment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className='subpage_header'>
      <PageBanner
        breadcrumb={"Banking"}
        breadcrumb2={"Bill Payment"}
        title={"Bill Payment"}
        preLink= {'/banking'}
        content={"Banking payment services refer to the various methods and systems provided by banks to facilitate the transfer of funds between individuals, businesses, and other entities. These services play a crucial role in enabling seamless financial transactions and managing money effectively."}
        image={Service_img10} />
      </div>
      <FeaturedBenefits title="Featured Benefits" content="Micro ATMs, also known as mini ATMs or mobile ATMs, are portable and handheld devices that provide basic banking services to customers in remote or underserved areas." routeName="Bill Payments"/>
        <CommomBanner  title={"Get the Bill Payments for Your Needs"}/>
        <SloveFor routeName="Bill Payments" />
        
        <FooterBankingServices />
        <GetinTouch />
        
       
    </div>
  )
}

export default BillPayment