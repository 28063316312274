import React, { useEffect } from 'react'
import PageBanner from '../../../components/page-banner/PageBanner'
import { MULTICURRENCYCARDBANNERBG } from '../../../assets/images'
import { FeaturedBenefits } from '../../../components/featured-benefits/featured-benefits'
import CommomBanner from '../../../components/commonbanner/CommomBanner'
import { SloveFor } from '../../../components/slovefor/SloveCard'
import GetinTouch from '../../../components/get-in-touch/GetinTouch'
import FooterServices from '../../../components/Footer-services/FooterServices'

const MulticurrencyCard = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
    <div className='subpage_header'>
    <PageBanner
        breadcrumb={"Card Issuance"}
        breadcrumb2={"Multi Currency Travel Card"}
        title={"Multi Currency Travel Card"}
        preLink= {'/card-issuance'}
        content={" multi-currency travel card is a prepaid card designed specifically for travelers who frequently visit multiple countries or need to deal with different currencies."}
        image={MULTICURRENCYCARDBANNERBG} />
    </div>
    <FeaturedBenefits title="Featured Benefits" content="A multi-currency travel card offers several featured benefits that make it an excellent option for travelers." routeName="multitravelcard" />
    <CommomBanner title={"Get the Multi Currency Travel Card for your needs"}  />
    <SloveFor   routeName="multitravel"  />
    <FooterServices />
    <GetinTouch/>
    </>
  )
}

export default MulticurrencyCard