import React, { useEffect } from 'react'
import PageBanner from '../../../components/page-banner/PageBanner';
import { FeaturedBenefits } from '../../../components/featured-benefits/featured-benefits';
import CommomBanner from '../../../components/commonbanner/CommomBanner';
import { SloveFor } from '../../../components/slovefor/SloveCard';
import FooterPaymentsServices from '../../../components/footer-payments-services/FooterPaymentsServices';
import GetinTouch from '../../../components/get-in-touch/GetinTouch';
import { PREPAIDBG } from '../../../assets/images';

const PrepaidCard = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      return (
        <>
        <div className='subpage_header'>
        <PageBanner
            breadcrumb={"Payment Services"}
            breadcrumb2={"Prepaid Card"}
            title={"Prepaid Card"}
            preLink= {'/payment-services'}
            content={`A prepaid card is a type of payment card that is preloaded with a specific amount of money by the cardholder before use. It works similarly to a debit or credit card but with a key distinction`}
            image={PREPAIDBG} />
        </div>
         <FeaturedBenefits title="Featured Benefits" content="Prepaid recharge, also known as mobile top-up or prepaid credit recharge, offers numerous benefits for mobile phone users." routeName="prepaid" />
        <CommomBanner title={"Get the Mobile and DTH Recharge for your needs"}  />
        <SloveFor   routeName="prepaid"  />
        <FooterPaymentsServices />
        <GetinTouch />
        </>
      )
}

export default PrepaidCard