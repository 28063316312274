import React, { useEffect } from 'react'
import PageBanner from '../../../components/page-banner/PageBanner';
import { FeaturedBenefits } from '../../../components/featured-benefits/featured-benefits';
import CommomBanner from '../../../components/commonbanner/CommomBanner';
import { SloveFor } from '../../../components/slovefor/SloveCard';
import FooterPaymentsServices from '../../../components/footer-payments-services/FooterPaymentsServices';
import GetinTouch from '../../../components/get-in-touch/GetinTouch';
import { AADHAARBG } from '../../../assets/images';

const AadhaarPay = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      return (
        <>
        <div className='subpage_header'>
        <PageBanner
            breadcrumb={"Payment Services"}
            breadcrumb2={"Aadhaar Pay"}
            title={"Aadhaar Pay"}
            preLink= {'/payment-services'}
            content={`Aadhaar Pay is a digital payment system introduced by the Indian government to promote cashless transactions and financial inclusion.`}
            image={AADHAARBG} />
        </div>
         <FeaturedBenefits title="Featured Benefits" content="Aadhaar Pay is a digital payment platform that leverages the Aadhaar biometric system to facilitate cashless transactions. Aadhaar Pay offers several benefits, both for consumers and merchants." routeName="aadhaarpay" />
        <CommomBanner title={"Get the Mobile and DTH Recharge for your needs"}  />
        <SloveFor   routeName="aadhaarpay"  />
        <FooterPaymentsServices />
        <GetinTouch />
        </>
      )
}

export default AadhaarPay