import React, { useEffect } from 'react'
import PageBanner from '../../../components/page-banner/PageBanner'
import { FeaturedBenefits } from '../../../components/featured-benefits/featured-benefits'
import CommomBanner from '../../../components/commonbanner/CommomBanner'
import { SloveFor } from '../../../components/slovefor/SloveCard'
import FooterServices from '../../../components/Footer-services/FooterServices'
import GetinTouch from '../../../components/get-in-touch/GetinTouch'
import { CPRCARDBG } from '../../../assets/images'

const GprCards = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
       <div className='subpage_header'>
    <PageBanner
        breadcrumb={"Card Issuance"}
        breadcrumb2={"GPR Cards"}
        title={"GPR Cards"}
        preLink= {'/card-issuance'}
        content={"A reloadable prepaid card allows you to add more money. This type of card is sometimes called a General-Purpose Reloadable Card, or GPR Card. Some cards start out as non-reloadable but can be reloaded once you complete a registration process."}
        image={CPRCARDBG} />
    </div>
    <FeaturedBenefits title="Featured Benefits" content="Customer can use this card to make purchases, utility bill payments, online shopping, tickets bookings and cash withdrawal at ATM." routeName="gprcard" />
    <CommomBanner title={"Get the GPR cards for your needs"}  />
    <SloveFor routeName="gprcard"  />
    <FooterServices />
    <GetinTouch/>
        
    </>
  )
}

export default GprCards