import React, { useEffect } from 'react'
import PageBanner from '../../components/page-banner/PageBanner'
import { Service_img6 } from '../../assets/images'
import { SloveFor } from '../../components/slovefor/SloveCard'
import GetinTouch from '../../components/get-in-touch/GetinTouch'
import FooterBankingServices from '../../components/footer-banking-services/FooterBankingServices'
import CommomBanner from '../../components/commonbanner/CommomBanner'
import { FeaturedBenefits } from '../../components/featured-benefits/featured-benefits'

const PrepaidRecharges = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
       <div className='subpage_header'>
       <PageBanner
        breadcrumb={"Banking"}
        breadcrumb2={"Prepaid Recharges"}
        title={"Prepaid Recharges"}
        preLink= {'/banking'}
        content={"Prepaid recharge refers to the process of adding credit or funds to a prepaid account, typically used for mobile phones, data plans, or other prepaid services."}
        image={Service_img6} />
       </div>
  
        <FeaturedBenefits title="Featured Benefits" content="Prepaid recharge, also known as mobile top-up or prepaid credit recharge, offers numerous benefits for mobile phone users." routeName="Prepaid Recharges"/>
        <CommomBanner  title={"Get the Prepaid Recharges for Your Needs"}/>
        <SloveFor routeName="Prepaid Recharges" />
        
        <FooterBankingServices />
        <GetinTouch />
        
        
    </div>
  )
}

export default PrepaidRecharges