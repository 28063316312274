import React, { useEffect } from 'react'
import PageBanner from '../../../components/page-banner/PageBanner'
import { FeaturedBenefits } from '../../../components/featured-benefits/featured-benefits'
import CommomBanner from '../../../components/commonbanner/CommomBanner'
import { SloveFor } from '../../../components/slovefor/SloveCard'
import FooterServices from '../../../components/Footer-services/FooterServices'
import GetinTouch from '../../../components/get-in-touch/GetinTouch'
import { MOBILITYCARDBG } from '../../../assets/images'

const MobilityCardPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
    <div className='subpage_header'>
 <PageBanner
     breadcrumb={"Card Issuance"}
     breadcrumb2={"Mobility Cards"}
     title={"Mobility Cards"}
     preLink= {'/card-issuance'}
     content={`Mobility card feature enables the customers to use as a travel card for travelling in Metro Rail and Buses where this facility is enabled.`}
     image={MOBILITYCARDBG} />
 </div>
 <FeaturedBenefits title="Featured Benefits" content="Mobility cards, often known as travel cards or transit cards, are convenient tools that offer a range of benefits for users who frequently use public transportation systems." routeName="mobilitycard" />
 <CommomBanner title={"Get the Mobility cards for your needs"}  />
 <SloveFor routeName="mobilitycard"  />
 <FooterServices />
 <GetinTouch/>
     
   </>
  )
}

export default MobilityCardPage