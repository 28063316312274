import React, { useEffect } from 'react'
import PageBanner from '../../components/page-banner/PageBanner'
import { Service_img5  } from '../../assets/images'
import { SloveFor } from '../../components/slovefor/SloveCard'
import GetinTouch from '../../components/get-in-touch/GetinTouch'
import FooterBankingServices from '../../components/footer-banking-services/FooterBankingServices'
import CommomBanner from '../../components/commonbanner/CommomBanner'
import { FeaturedBenefits } from '../../components/featured-benefits/featured-benefits'

const EMT = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className='subpage_header'>
      <PageBanner
        breadcrumb={"Banking"}
        breadcrumb2={"Electronic Money Transfers"}
        title={"Electronic Money Transfers"}
        preLink= {'/banking'}
        content={"Electronic money transfers refer to the process of sending and receiving money electronically, without the need for physical cash or checks."}
        image={Service_img5} />
      </div>
      <FeaturedBenefits title="Featured Benefits" content="Micro ATMs, also known as mini ATMs or mobile ATMs, are portable and handheld devices that provide basic banking services to customers in remote or underserved areas." routeName="EMT"/>
        <CommomBanner  title={"Get the Electronic Money Transfers for Your Needs"}/>
        <SloveFor routeName="Electronic Money Transfers"  />
        
        <GetinTouch />
        <FooterBankingServices />
       
    </div>
  )
}

export default EMT