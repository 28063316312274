import { styled } from 'styled-components';
import Card from 'react-bootstrap/Card';

const FeaturedCard = ({ title, content, image, name}) => {

    const CardStyle = {
        // minHeight: "170px",
        height: "100%",
        width: "100%",
        background: "#eff9ff",
        // boxShadow: "4px 4px 16px 0px rgb(242, 242, 242)",
        overflow: "hidden",
        padding: "20px",
        borderRadius: "0px",
        border: "none"
    }
    const CardTitle = styled.h3`
       font-size: min(max(16px, calc(1rem + (24 - 16) * ((100vw - 320px) / (1400 - 320)))), 24px);
       color: rgba(0, 0, 0, 0.85);
       font-family: 'Open Sans', sans-serif;
       font-weight: 700; 
       margin-bottom: 12px;
   `
    const CardContent = styled.p`
       font-size: min(max(14px, calc(0.875rem + ((1vw - 3.2px) * 0.25))), 16px);
       color: rgba(14, 10, 0, 0.75);
       font-family: 'Open Sans', sans-serif;
       font-weight: 600; 
       line-height: 28px;
    `
    const Border = styled.div`
    background: orange;
    width: 3px;
    height: 40px;
    position: absolute;
    z-index: 1;
    margin-top: 15px;
    `

    return (
        <>
        <Border></Border>
            <Card className='accu_card' style={CardStyle}>
                    <CardTitle>{title}</CardTitle>
                    <CardContent>{content}</CardContent>
            </Card>
        </>
    )
}

export default FeaturedCard;
