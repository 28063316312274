import React from 'react'
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

const ServiceCard = styled.div `
 
       height: 347px;
       width: 100%;
       box-shadow: 4px 4px 16px 0px rgb(242, 242, 242);
       padding: 26px;
       border: 2px solid #f5f5f5 ;
       position: relative;
       border-radius: 10px;
       @media screen and (max-width:991px){
        height: 100%;
       }
`
const ServiceCardText = styled.h1 `
     font-size: min(max(22px, calc(1.375rem + (40 - 22) * ((100vw - 320px) / (1400 - 320)))), 40px);
color: #0e0a00;
font-family: 'Open Sans', sans-serif;
font-weight: 800; 
`
const ServiceCardSmall = styled.p `
   font-size: min(max(14px, calc(0.875rem + (16 - 14) * ((100vw - 320px) / (1400 - 320)))), 16px);
color: #0E0A00BF;
font-family: 'Open Sans', sans-serif;
font-weight: 500; 
margin-top: 20px;
line-height: 28px;

`
const Outlinebtn = styled.button `
    background: transparent;
    color: #0E0A00;
    font-family: 'Open Sans',sans-serif;
    font-weight: 700;
    border: 1px solid #0E0A00;
    padding: 6px 38px;
    font-size: min(max(14px, calc(0.875rem + (20 - 14) * ((100vw - 320px) / (1400 - 320)))), 20px);
    border-radius: 5px;
    margin-top: 8px;

    &:hover{
      background: #0E0A00;
      color: #fff;

    }


`

const ServicesCard = ({title, content, btn, link}) => {

  return (
    <>
       <ServiceCard>
        <div style={{height: "80%"}}>
        <ServiceCardText>{title}</ServiceCardText>
       <ServiceCardSmall>{content}</ServiceCardSmall>
        </div>
        <div style={{height: "20%"}}>

          <Link to={link}>
          <Outlinebtn >{btn}</Outlinebtn>
          </Link>

       

        </div>
       </ServiceCard>
    </>
  )
}

export default ServicesCard