import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { BiChevronRight } from 'react-icons/bi'
import styled from 'styled-components'


const Bread = styled.p`
  font-size: min(max(11px, calc(0.6875rem + (16 - 11) * ((100vw - 320px) / (1400 - 320)))), 16px);
color: #000;
font-family: 'Open Sans',sans-serif;
opacity: 50%;
font-weight: 600;
margin: 0;

@media screen and (max-width:540px) {
    font-size:12px !important;
    
}
@media screen and (min-width:991px) and (max-width:1280px) {
    font-size:11px !important;
}

`

const Activebread = styled.p`
 font-size: min(max(11px, calc(0.6875rem + (16 - 11) * ((100vw - 320px) / (1400 - 320)))), 16px);
color: #000;
font-family: 'Open Sans',sans-serif;
opacity: 75%;
font-weight: 600;
margin: 0;
cursor: pointer;
&:hover{
    opacity: 80% !important;
}
@media screen and (max-width:540px) {
    font-size:12px !important;
    
}
@media screen and (min-width:991px) and (max-width:1280px) {
    font-size:11px !important;
}
`

const TermofUse = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div>
        <Container>
            <div className="d-flex-acenter mt-3">
        <Bread>Home</Bread> 
        <BiChevronRight className='rightarrow' />
         <Activebread>Terms and Conditions</Activebread>
        </div>
        <div className='terms'>
            <h2>Terms and Conditions</h2>
            <p>The website Accupayd.in is operated by ACCUPAYD Tech Private limited. UThe usage of "us", "we" and "our" in the website refers to ACCUPAYD Tech Private Limited. This website offers its information, tools and services to the users on condition that all its terms and conditions as stated here are accepted. It will be understood that any person visiting this website, using its tools and services and making transactions on this has read the terms and conditions mentioned herewith and accepts them. They are bound by its terms and conditions and policies. These terms and conditions apply to all users of this website, including without limitation its users who are vendors, customers, tenants, landlords, merchants etc.</p>
            <p>Please read and understand the terms given hereunder before accessing or using the website and its services.</p>
            <p>All new services, content or tools that may be added to the website in due course will also be subject to the terms and conditions mentioned in this website. ACCUPAYD Technologies Private Limited reserves the right to make modifications to the terms and conditions as it may deem necessary. It is the responsibility of the user to check this periodically.</p>
            <p>It is also understood and accepted that all the users of this website are above the age of 18 and are legally eligible to consent to this website. Users of this website may not violate any laws locally, nationally or internationally.</p>
            <p>The user may not use this website for any transaction that is illegal, unauthorised or violates any laws including but not limited to copyright laws. The website may not be used for any transaction pertaining to payments towards pornography, gambling, piracy or any other antisocial activities. Accupayd reserves the right to refuse service to any persons at any time.
</p>
<p>The users understand that information shared on this website (other than credit card information and bank accounts) may be shared unencrypted over various networks and may undergo changes to adapt to technical requirements of different networks and devices. Credit card information will always and only be transferred after encryption.</p>
<p>Accupayd shall not be held responsible if there are delays that occur on account of incorrect or incomplete information being provided in the website. The user agrees to provide current, complete and accurate transaction information on the website. The user also agrees to make prompt updates on account information which includes expiry date of credit card etc that enables successful completion of transactions as needed.</p>
<p>Accupayd may be used for payment of rentals, security deposit and society maintenance charges through credit card. It may also be used for payment of utility and other vendors for services rendered. The website can also be used to receive and make payments to individuals for sale of products or services.</p>
<p>Users may not indulge in circular transactions or self-transactions that involve transferring money to their own accounts.</p>
<p>Successful payments CANNOT be refunded or reversed and shall be credited to the beneficiary in maximum of 3 to 7 working days. Accupayd is NOT responsible for transfers made to incorrect bank accounts against biller's or landlord's accounts as registered by the user on our dashboard. Convenience fee shall not be refunded in case of non-conformance with any of the terms.</p>
<p>Accupayd reserves the right to hold back any payment which it feels as suspicious or invalid unless it is completely validated. A rent/security deposit payment is considered valid if there exists a rental agreement between the tenant and the landlord with the rent amount equivalent to what is being transferred on Accupayd.</p>
<p>Accupayd reserves the right to report such transactions to the concerned regulatory authorities of banking partners, as it deems in case of fraudulent activity detected. It reserves the right to suspend your user account and related payment modes in case of non-conformance to any of the above terms.</p>
        </div>
        </Container>
    </div>
  )
}

export default TermofUse