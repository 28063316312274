export const GprCard = [
    {title: 'Convenience', content: `Customer can use this card to make purchases, utility bill payments, online 
    shopping, tickets bookings and cash withdrawal at ATM. It's a General Purpose 
    Reloadable (GPR) prepaid card that is a simple and efficient payment solution for 
    customers.`},
    {title: 'Security', content: "GPR cards provide a safer alternative to carrying cash. If the card is lost or stolen, users can typically report it and have the remaining balance protected or transferred to a new card."},
    {title: 'Financial Privacy', content: "Since GPR cards are not linked to a bank account, they offer a certain level of financial privacy for users who prefer to keep their spending separate from their main banking activities."},
    {title: ' No Interest Charges', content: 'Unlike credit cards, GPR cards do not incur interest charges, making them debt-free and an ideal option for those who want to avoid credit-related expenses.'},
    {title: 'Direct Deposit and Payroll', content: 'Some GPR cards allow users to set up direct deposit for receiving paychecks or government benefits, offering a convenient way to access funds without traditional banking.'},
    {title: 'Travel Convenience', content: 'GPR cards can be a practical solution for travelers, allowing them to carry funds in multiple currencies on a single card and avoid foreign transaction fees.'}

]