import React, { useEffect, useRef, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { CardIssuanceAccord } from '../../utils/CardIssuanceAccord';
import { styled } from 'styled-components';




const ProductAccordian = () => {

  const [accclour, setAccclour] = useState(false)


  const accordref = useRef(null)

  useEffect(() => {
    console.log(accordref.current)

    return () => {

    }
  }, [])


  const handleClick = (e, index) => {

    e.target.classList.toggle("active")

    console.log(index, "clicked index")
    console.log(e.target.classList.toggle("active"))





  }

  const Titles = styled.p`
 padding: 12px;
 color: #0E0A00BF;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: min(max(16px, calc(1rem + (20 - 16) * ((100vw - 320px) / (1400 - 320)))), 20px);
    background-color: #E8F5FF !important;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    box-shadow: none !important;
    border-radius: 0px !important;
    cursor: pointer;
    &:hover{
      font-weight: 700;
    }
 `



  return (
    <div className='page_accord'>

      {/* <Accordion ref={accordref}  >

        {
          CardIssuanceAccord.map((item, index) => {
            return (
                <Accordion.Item className={accclour === true ? 'added' : 'removed' }  key={index} eventKey={index} onClick={(e) =>{handleClick(e, index)} }>
                <Accordion.Header>{item.accrodTitle}</Accordion.Header>
                <Accordion.Body>
                  {
                    item.accrodContent

                  }

                </Accordion.Body>
              </Accordion.Item>
             
            )
          })
        }






      </Accordion> */}

      <>
              <Titles>Card Application Processing</Titles>
              <Titles>BIN and Switch Management</Titles>
              <Titles>Card Authorisation and Issuance</Titles>
              <Titles>Transaction processing and posting</Titles>
              <Titles>Merchant Management, Billing and Payment Processing</Titles>
              <Titles>Customer Services and Dispute Handling</Titles>
              <Titles>Loyalty and Risk Management</Titles>
              </>



    </div>
  )
}

export default ProductAccordian