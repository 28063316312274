import React, { useState } from 'react'
import { Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap'
import { styled } from 'styled-components'
import { Getintouch, Thankyou } from '../../assets/images'
import { object, string, number } from 'yup';
import { Formik, Field, useFormik } from "formik";
import URL from '../../apiurl';
import Modal from 'react-bootstrap/Modal';
import { Loader } from '../../assets/images';
import Success_popup from '../success_popup/Success_popup';
  
 
const emailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ ;
const mobileReg = /^[6-9]\d{9}$/  // 6|7|8|9 123456789    -10 digits only allowed

const validationSchema = object().shape({
    name: string().trim().required('Please provide the full name'),
    email: string().required('Please provide the email id ')
        .matches(emailReg, "Please provide the valid email id"),
    mobile: string().required('Please provide the phone number')
        .matches(mobileReg, "Please provide the valid phone number"),
    message: string().trim().required('Please provide your message')
})
const GetinTouch = () => {
    const initialValues = {
        name: '',
        email: '',
        mobile: '',
        message: '',
    }
    const [showed, setShowed] = useState(false);
    const handleCloseSecond = () => setShowed(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const submitForm = async (data) => {
        setIsSubmitting(true);
        //console.log(`${URL}/enquiry/create`);
        try {

            const bodyData = {
                "name": data?.name,
                "mobile_no": data?.mobile,
                "email": data?.email,
                "message": data?.message
            }

            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(bodyData)
            }
            await fetch(`${URL}/enquiry/create`, options)
                .then(res => res.json())
                .then(res => {

                    console.log(res)
                    // onClose() 
                    setIsSubmitting(false);
                    setShowed(true)
                })
        } catch (error) {
             console.log(error)
            setIsSubmitting(false);
        }
    }
    const Formlabel = styled.label`
     font-size: min(max(14px, calc(0.875rem + (16 - 14) * ((100vw - 320px) / (1400 - 320)))), 16px);
     font-family: 'Inter';
     font-weight: 700;
     color: #5F5F5F;
     margin-bottom: 5px;
`
    const Formsec = styled.div`
        background: #FFF9E8;
        padding: 3rem;
    `
    const Formheading = styled.h1`
        font-size: min(max(16px, calc(0.875rem + (32 - 16) * ((100vw - 320px) / (1400 - 320)))), 32px);
        font-weight: 700;
        font-family: 'Open Sans', sans-serif;
        color: #0E0A00;
        opacity: 85%;
    `
    const Formcontent = styled.p`
        font-size: min(max(14px, calc(0.875rem + (18 - 14) * ((100vw - 320px) / (1400 - 320)))), 18px);
        color: #0E0A00;
        font-family: 'Open Sans', sans-serif;
        opacity: 75%;
        margin-bottom: 30px;
    `
    const SubmitBtn = styled.button`
        background: linear-gradient(90deg, rgba(243,154,52,1) 0%, rgba(252,199,58,1) 50%);
        color: #0E0A00;
        font-family: 'Open Sans',sans-serif;
        font-weight: 700;
        border: none;
        padding: 8px 26px;
        font-size: min(max(14px, calc(0.875rem + (20 - 14) * ((100vw - 320px) / (1400 - 320)))), 20px);
        border-radius: 5px;
        margin-top: 20px;
        &:hover{
      background: #0E0A00;
        color: #fff !important;
     }
    `


    return (
        <>
            <Container className=' mt-110'>
                <Formsec className='form-sec '>
                    <Row>
                        <Col lg={6}>
                            <img className='img-fluid getintouch' src={Getintouch} alt="gettouch" />
                        </Col>
                        <Col lg={6}>
                            <div className='p-3'>
                                <Formheading>
                                    Impressed? Get in touch to learn about our complete suite.
                                </Formheading>
                                <Formcontent>Launch market-winning fintech products today.</Formcontent>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={submitForm}
                                >
                                    {({
                                        touched,
                                        isValid,
                                        isInvalid,
                                        errors,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        values,
                                        validateForm,
                                    }) => (


                                        <form onSubmit={handleSubmit} className='gettouch'>

                                            <Form.Floating className="mb-4" name="name">

                                                <Form.Control

                                                    type="text"
                                                    name="name"
                                                    placeholder="Enter Full Name *"
                                                    //  value={Formik.values.name}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.name}
                                                />

                                                <label htmlFor="floatingInputCustom">Full Name <span className='text-danger'>*</span></label>

                                                <Form.Control.Feedback style={{paddingLeft:'13px'}} type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </Form.Floating>

                                            <Form.Floating className="mb-4">


                                                <Form.Control
                                                    type="email"
                                                    name="email"
                                                    onChange={handleChange}
                                                    placeholder="Enter Email *"
                                                    isInvalid={!!errors.email}
                                                />

                                                <label htmlFor="floatingInputCustom">Email <span className='text-danger'>*</span></label>

                                                <Form.Control.Feedback style={{paddingLeft:'13px'}} type="invalid">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            </Form.Floating>

                                            <Form.Floating className="mb-4">
                                                <Form.Control
                                                    name="mobile"
                                                    type="number"
                                                    placeholder="Enter Phone Number *"
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.mobile}
                                                />
                                                <label htmlFor="floatingInputCustom">Phone Number <span className='text-danger'>*</span></label>

                                                <Form.Control.Feedback style={{paddingLeft:'13px'}} type="invalid">
                                                    {errors.mobile}
                                                </Form.Control.Feedback>
                                            </Form.Floating>
                                            <Form.Group className="mb-4">
                                            <label style={{paddingLeft:'13px'}}  htmlFor="floatingInputCustom">How can we help you <span className='text-danger'>*</span></label>
                                                <Form.Control
                                                 as="textarea"
                                                 rows={3} 
                                                    name="message"
                                                    type="text"
                                                     
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.message}
                                                />
                                               

                                                <Form.Control.Feedback style={{paddingLeft:'13px'}} type="invalid">
                                                    {errors.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <SubmitBtn type="submit">{isSubmitting ? 'Submitting...' : 'Submit'}</SubmitBtn>
                                            {isSubmitting ? <img className='img-fluid loader' src={Loader} alt="loading" /> : '' }
                                        </form>
                                    )}
                                </Formik>

                                <Success_popup show={showed} onHide={handleCloseSecond}/>
                            </div>
                        </Col>
                    </Row>
                </Formsec>
            </Container>
        </>
    )
}

export default GetinTouch