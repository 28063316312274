import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Thankyou } from '../../assets/images'
import { styled } from 'styled-components'
import {AiOutlineClose} from 'react-icons/ai'


const ThankTitle = styled.h2`
font-size: min(max(16px, calc(0.875rem + (32 - 16) * ((100vw - 320px) / (1400 - 320)))), 32px);
color: #0E0A00;
  font-family: 'Open Sans',sans-serif;
  font-weight: 700;
`

const ThankContent = styled.p`
font-size: min(max(14px, calc(0.875rem + (16 - 14) * ((100vw - 320px) / (1400 - 320)))), 16px);
color: #999999;
  font-family: 'Open Sans',sans-serif;
  font-weight: 500;
`


const Success_popup = ({show, onHide}) => {
  return (
    <div>
        <Modal show={show} onHide={onHide} centered>
        <Modal.Header  className='br-line-none' style={{justifyContent:'right'}}>
        <Button variant="light"  onClick={onHide}><AiOutlineClose /></Button>
        </Modal.Header>
        <Modal.Body className='pt-0'>
          <div className='text-center greeting_content'>
            <img src={Thankyou} alt="thank-gif" />
            <ThankTitle>Thank you contacting us</ThankTitle>
            <ThankContent>Someone from our team will get back to you within a day. Usually, we get back in a few hours.</ThankContent>
          </div>
        </Modal.Body>


      </Modal>
    </div>
  )
}

export default Success_popup