import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion';
import { BiChevronRight } from 'react-icons/bi'
import styled from 'styled-components'


const Bread = styled.p`
  font-size: min(max(11px, calc(0.6875rem + (16 - 11) * ((100vw - 320px) / (1400 - 320)))), 16px);
color: #000;
font-family: 'Open Sans',sans-serif;
opacity: 50%;
font-weight: 600;
margin: 0;

@media screen and (max-width:540px) {
    font-size:12px !important;
    
}
@media screen and (min-width:991px) and (max-width:1280px) {
    font-size:11px !important;
}

`

const Activebread = styled.p`
 font-size: min(max(11px, calc(0.6875rem + (16 - 11) * ((100vw - 320px) / (1400 - 320)))), 16px);
color: #000;
font-family: 'Open Sans',sans-serif;
opacity: 75%;
font-weight: 600;
margin: 0;
cursor: pointer;
&:hover{
    opacity: 80% !important;
}
@media screen and (max-width:540px) {
    font-size:12px !important;
    
}
@media screen and (min-width:991px) and (max-width:1280px) {
    font-size:11px !important;
}
`

const FAQ = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div>
        <Container className='faq-accordian'>
            <div className="d-flex-acenter mt-3">
        <Bread>Home</Bread> 
        <BiChevronRight className='rightarrow' />
         <Activebread>FAQ</Activebread>
        </div>
        <div className='faq'>
        <h2><b>FAQ</b></h2>
        <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>What is Fintech?</Accordion.Header>
        <Accordion.Body>
        New technology that seeks to improve and automate the delivery and use of financial services. At its core, fintech is utilized to help companies, business owners, and consumers better manage their financial operations, processes, and lives.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>What are the 5 key technologies in fintech?</Accordion.Header>
        <Accordion.Body>
        Below are the top five technologies used in Fintech,
        <ul className='mt-15'>
            <li>AI and Machine Learning. Artificial Intelligence (AI) and Machine Learning (ML) are key fixtures of fintech</li>
            <li>Big Data Analytics</li>
            <li>Application Programming Interface</li>
            <li>Cybersecurity</li>
            <li>Blockchain</li>
        </ul>

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>What is Clearing and dispute management?</Accordion.Header>
        <Accordion.Body>
        Helps to manage clearing and end-to-end dispute management needs. When integrated with the cards management system the clearing and dispute management system will provide you with one view of the entire transaction lifecycle in a single powerful platform.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>What is Prepaid Cards?</Accordion.Header>
        <Accordion.Body>
        Supports end-to-end card lifecycle management for prepaid card issuance. It is designed to cater to offerings such as multi-currency general purpose reloadable prepaid cards, gift cards, payroll cards, government benefit cards, transit cards, travel cards, student ID cards, business cards, Incentive cards etc.</Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>What is Corporate Cards?</Accordion.Header>
        <Accordion.Body>
        Supports end-to-end card life cycle management of corporate / commercial card issuance. It is designed to cater to both Corporate Prepaid Card and Corporate Credit Card issuance.
       </Accordion.Body>
      </Accordion.Item>
    </Accordion>
        </div>
        
        </Container>
    </div>
  )
}

export default FAQ