import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/home/Home';
import Navigation from './components/header/Navigation';
import Footer from './components/footer/Footer';
import CardIssuance from './pages/card-issuance/CardIssuance';
import BankingPage from './pages/banking/banking';
import PaymentServicesPage from './pages/payment-services/PaymentServices';
import MicroATM from './pages/banking/micro-atm';
import BillPayment from './pages/banking/bill-payment';
import { KickStart } from './pages/kickstart/KickStart';
import EMT from './pages/banking/emt';
import PrepaidRecharges from './pages/banking/prepaid-recharges';
import CoporateCard from './pages/card-issuance/coporate-cards/CoporateCard';
import SafeGold from './pages/banking/safe-gold';
import NeoBanking from './pages/banking/neo-banking';
import MulticurrencyCard from './pages/card-issuance/multi-currency-card/MulticurrencyCard';
import GiftcardPage from './pages/card-issuance/gift-card/GiftcardPage';
import GprCards from './pages/card-issuance/gpr-cards/GprCards';
import MobilityCardPage from './pages/card-issuance/mobility-cards/MobilityCardPage';

import VirtualCardsPage from './pages/card-issuance/virtual-card/VirtualCardsPage';
import PayRent from './pages/payment-services/pay-rent/PayRent';
import MobileDthRecharge from './pages/payment-services/mobile-dth-recharge/MobileDthRecharge';
import AadhaarPay from './pages/payment-services/aadhaar-pay/AadhaarPay';
import PrepaidCard from './pages/payment-services/prepaid-card/PrepaidCard';
import CreditcardBills from './pages/payment-services/creditcard-bils/CreditcardBills';
import UtilityBills from './pages/payment-services/utility-bills/UtilityBills';
import About from './pages/about/About';
import TermofUse from './pages/term-of-use/termofUse';
import FAQ from './pages/faq/faq';

function App() {

  return (

  <>
   <Navigation />
    <Routes>
      <Route path='/' index element = { <Home/>}/>
      <Route path='/card-issuance' element = { <CardIssuance active = "active state" />}  />
      <Route path='/banking' element = { <BankingPage/>}/>
      <Route path='/payment-services' element = { <PaymentServicesPage/>}/>
      <Route path='/banking/micro-atm' element = { <MicroATM/>}/>
      <Route path='/banking/bill-payment' element = { <BillPayment/>}/>
      <Route path='/banking/electronic-money-transfer' element = { <EMT/>}/>
      <Route path='/banking/prepaid-recharges' element = { <PrepaidRecharges/>}/>
      <Route path='/banking/safe-gold' element = { <SafeGold/>}/>
      <Route path='/banking/neo-banking' element = { <NeoBanking/>}/>
      <Route path='/kickstart' element = { <KickStart/>}/>
      <Route path='/card-issuance/coporate-cards' element = { <CoporateCard/>}/> 
      <Route path='/card-issuance/multi-currency-travel-card' element = { <MulticurrencyCard/>}/> 
      <Route path='/card-issuance/gift-card' element = { <GiftcardPage/>}/> 
      <Route path='/card-issuance/gpr-card' element = { <GprCards/>}/> 
      <Route path='/card-issuance/mobility-card' element = { <MobilityCardPage/>}/> 
      <Route path='/card-issuance/virtual-card' element = { <VirtualCardsPage/>}/> 
      <Route path='/payment-services/pay-rent' element = { <PayRent/>}/> 
      <Route path='/payment-services/mobile-dth-recharge' element = { <MobileDthRecharge/>}/> 
      <Route path='/payment-services/aadhaar-pay' element = { <AadhaarPay/>}/> 
      <Route path='/payment-services/prepaid-card' element = { <PrepaidCard/>}/> 
      <Route path='/payment-services/creditcard-bills' element = { <CreditcardBills/>}/> 
      <Route path='/payment-services/utility-bills' element = { <UtilityBills/>}/> 
      <Route path='/about' element = { <About/>}/> 
      <Route path='/TermofUse' element = { <TermofUse/>}/>
      <Route path='/faq' element = { <FAQ/>}/> 
      <Route path='*'  element={<Home/>} />
    </Routes>
    <Footer />
  </>
  );
}

export default App;
