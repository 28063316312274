import React, { useLayoutEffect } from 'react'
import ProductAccordian from '../product-accordian/ProductAccordian'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { styled } from 'styled-components';
import { CARDINSSUANCEIMG } from '../../assets/images';



const Smallcontet = styled.p `

color: #0E0A00BF;
font-family: 'Open Sans', sans-serif;
font-weight: 600;
font-size: min(max(15px, calc(0.9375rem + (18 - 15) * ((100vw - 320px) / (1400 - 320)))), 18px);
line-height: 28px;


`


const CardIssunaceAccord = () => {


 return (

<>
    <Container className='mt-175 mb-110' id='scroll_section'>
        <Row>
          <Smallcontet> <b>Card issuance</b> refers to the process of creating and distributing physical or virtual cards to individuals or organizations for various purposes. These cards can be credit cards, debit cards, identification cards, access cards, accupayd - Giftcards, loyalty cards, and more. The issuance of cards typically involves several steps, which may vary depending on the type of card and the issuing institution.</Smallcontet>
          </Row>
          <div className='mt-50 '>
            <Row>
            <Col lg={6} style={{alignSelf: 'center'}}>
        <img className='img-fluid' src={CARDINSSUANCEIMG} alt="card" />
        </Col>
        <Col lg={6}>
           <ProductAccordian  />
        </Col>
      </Row>
          </div>

    </Container>



      

        
    </>
  )
}

export default CardIssunaceAccord