import { BANKING_1, BANKING_2 ,BANKING_3, BANKING_4, BANKING_5, BANKING_6 } from "../assets/images";



export const BankingCardDetails = [
    
    {
        cardtitle : 'Micro ATM',
        image : BANKING_1,
        content: "Micro ATMs are likely to evolve and provide even more advanced and comprehensive banking services in the future. ",
        bg: '#B1DCFA',
        cardlink: '/banking/micro-atm'
    },
    {
        cardtitle : 'Electronic Fund Transfer',
        image : BANKING_2,
        content: "Instant money transfer refers to the swift and real-time transfer of funds from one account to another, typically facilitated through digital payment systems and electronic platforms. ",
        bg: '#FEEAB4',
        cardlink: '/banking/electronic-money-transfer'

    },
    {
        cardtitle : 'Bill Payments',
        image : BANKING_3,
        content: "Bill payments refer to the process of settling financial obligations or expenses by making payments to service providers or creditors for various services or products used. ",
        bg: '#B1DCFA',
        cardlink: '/banking/bill-payment'
        
    },
    {
        cardtitle : 'Prepaid Recharges',
        image : BANKING_4,
        content: 'These prepaid services are popular for their convenience, as users can pay in advance and use the services until the prepaid balance is exhausted or the validity period expires. ',
        bg: '#FEEAB4',
        cardlink: '/banking/prepaid-recharges'
    },
    {
        cardtitle : 'Neo Banking',
        image : BANKING_5,
        content: "Customers considering using a neo bank should research the bank's background, security measures, and any applicable fees or limitations before opening an account. ",
        bg: '#B1DCFA',
        cardlink: '/banking/neo-banking'
    },
    {
        cardtitle : 'Safe Gold',
        image : BANKING_6,
        content: 'The concept of safe gold aims to combine the traditional value and stability of gold with the convenience and accessibility of digital assets. ',
        bg: '#FEEAB4',
        cardlink: '/banking/safe-gold'
    },


]