import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { styled } from 'styled-components';
import { genericTypeAnnotation } from '@babel/types';
export const SecureCard = () => {
const commitments = [
{ count: '01', title: 'We use 256 bit Secure Sockets Layer (SSL) encryption technology to secure your Card online transactions.' },
{ count: '02', title: 'You can check the security certificate on our website, www.accupaydtech.com by clicking on the padlock icon that appears with the URL in the browser bar when you type the URL www.accupaydtech.com'  },
{ count: '03', title: 'We enable you to secure your Card online password from fraudulent use. Once you have started the registration process on Card online and provided your card details, we send you a One Time Password (OTP) to your registered email ID and mobile number as an additional factor of authentication You can complete the registration process and set your user ID and password only after entering the correct OTP.'},
{ count: '04', title: 'We also indicate the strength of your passwords, on the basis of your chosen character combination, i.e. combination of alphabets in small case and caps, numerals and special characters.'},
{ count: '05', title: 'We make sure that you are not reusing any one of your last ten passwords while re-setting your password.' },
{ count: '06', title: 'We provide a virtual keyboard as an additional security measure and encourage you to use it.' },
{ count: '07', title: 'Your Card online account gets automatically locked to prevent any misuse, if you have typed in an incorrect password 3 times in succession. You can either unlock the account online by generating a One Time Password that would be sent on your registered e-mail ID or by calling our helpline.' },
{ count: '08', title: 'The Card issuance online account gets automatically logged off after an inactive period of seven minutes.' },
{ count: '09', title: 'When you log in to your Accupayd Card online account, your last log in date and time is displayed in the header bar so you can check your account for any unauthorised activity.' },
{ count: '10', title: 'We only display the last four digits of your credit card number in our communication to you to safeguard your card from unauthorised usage.' },
{ count: '11', title: 'We only display the last four digits of your credit card number in our communication to you to safeguard your card from unauthorised usage.' },
]
const Section = styled.div`

`
const CardSection = styled.div`
background-color: #ffffff;
padding: 15px 0px;
padding-right: 50px;

`
const CountText = styled.h1`
color: #1072b8;
font-weight: 700;
font-size: min(max(20px, calc(1.125rem + (48 - 20) * ((100vw - 320px) / (1400 - 320)))), 48px);
font-family: 'Open Sans', sans-serif;
`
const ContentText = styled.h5`
color: rgba(14, 10, 0, 0.7);
font-weight: 600;
font-size: min(max(13px, calc(0.8125rem + (16 - 13) * ((100vw - 320px) / (1400 - 320)))), 16px);
font-family: 'Open Sans', sans-serif;
line-height: 28px;
`
const HeadeText = styled.h1`
font-size: min(max(22px, calc(1.375rem + (40 - 22) * ((100vw - 320px) / (1400 - 320)))), 40px);
color: #0E0A00D9;
font-family: 'Open Sans', sans-serif;
font-weight: 700;
position: relative;
padding-left: 16px;`
const HeadePara = styled.p`
font-size: min(max(14px, calc(0.875rem + (18 - 14) * ((100vw - 320px) / (1400 - 320)))), 18px);
color: #0e0a00a6;
font-family: 'Open Sans', sans-serif;
font-weight: 600;
position: relative;
padding-left: 16px;
width: 65%;
    margin: auto;`
return (
<>
<Section className='count-section secure-card mt-110'>
   <Container className=''>
   <div className='card_layout_header text-center'>
     <HeadeText>Our commitment to provide secure Card Issuance</HeadeText>
     <HeadePara className='sub-content'>We are committed to ensuring complete safety of Card Issuance - We follow a strong password and online security policy to provide you a safe online experience.</HeadePara>
      
  </div>
      <Row className='mt-15'>
         {
         commitments.map((item, index) => {
         return (
         <Col key={index} xl={6} lg={6} md={6} sm={12} xs={12} className='commitments' >
         <CardSection className='count-card'>
            <CountText>{item.count}</CountText>
            <ContentText>{item.title}</ContentText>
         </CardSection>
        
         </Col>
         )
         })
         }
      </Row>
   </Container>
</Section>
</>
)
}