import React from 'react'
import { LOGO } from '../../assets/images'
import { Col, Container, Row } from 'react-bootstrap'
import './footer.css'
import { MdLocationOn } from "react-icons/md";
import { BiLogoFacebook, BiSolidPhone } from "react-icons/bi";
import { IoLogoWhatsapp } from "react-icons/io";
import {FaFacebookF} from 'react-icons/fa'
import { GrLinkedinOption } from "react-icons/gr";
import { BiLogoTwitter } from "react-icons/bi";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div>
            <Container className='mt-75'>
                <div className='text-center py-5 br-line'>
                    <img src={LOGO} alt="" />
                </div>
                <Row className='py-5'>
                    <Col lg={4} md="6" className='mb-lg-50'>
                        <div className='foot-location'>
                            <MdLocationOn className='location-icon' />
                            <p>No 7, 3rd Street, <br /> Kamarajar Nagar, Chinna <br /> Chokkikulam, <br /> Madurai - 625002. </p>
                        </div>
                        <div className='foot-contact'>
                            <BiSolidPhone className='contact-icon' />
                            <div>
                            <p className='mb-0'><a className=' text-decoration-none' href="mailto:contact@accupaydtech.com">contact@accupaydtech.com</a></p>
                            <p className='mb-0 d-inline-block'><a className=' text-decoration-none' href="tel:+918447928988">+91 84479 28988 </a></p>
                            
                            </div>
                           
                           
                        </div>
                    </Col>
                    <Col lg={2} md='6'>
                        <div className='products'>
                            <h4>Products</h4>
                            <div className='d-flex flex-direction-column'>
                            <p className='mb-0 pb-12'><Link  to="/card-issuance">Card Issuance</Link></p>
                            <p className='mb-0 pb-12'><Link to="/banking">Banking</Link></p>  
                            <p className='mb-0 pb-12'><Link to="/payment-services">Payment Services</Link></p>  
                                </div>
                        </div>
                    </Col>
                    <Col lg={3} md='6' className='pl-8'>
                    <div className='products'>
                            <h4>Pages</h4>
                            <div className='d-flex flex-direction-column'>
                            <p className='mb-0 pb-12'><Link to={'/'}>Home</Link></p>
                            <p className='mb-0 pb-12'><Link  to={'/About'} >About Us</Link></p>
                            <p className='mb-0 pb-12'> <Link to={'/kickstart'}>Kickstart</Link></p>
                            <p className='mb-0 pb-12'> <Link to={'/faq'}>FAQ</Link></p>  
                            </div>
                        </div>
                    </Col>
                    <Col lg={2} md='6'>
                    <div className='products'>
                            <h4>Follow Us</h4>
                            <div className='d-flex'>
                               {/* <Link className='icon-bg'>
                               <FaFacebookF className='social-icon'/>
                               </Link> */}
                               
                                <Link  className='icon-bg' target='_blank' to='https://www.linkedin.com/company/accupaydtech/'>
                               <GrLinkedinOption className='social-icon'/></Link>
                              
                              {/* <Link className='icon-bg'>
                              <BiLogoTwitter className='social-icon'/>
                              </Link> */}
                               <Link className='icon-bg' target='_blank' to='https://instagram.com/accupayd?igshid=MmU2YjMzNjRlOQ==' >
                               <BiLogoInstagramAlt className='social-icon'/>
                               </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className='copyright py-5'>
                    <p>Copyright © 2023 Accupayd Tech Pvt Ltd</p>
                    {/* <p>Developed By accupayd</p> */}
                </div>
            </Container>
        </div>
    )
}

export default Footer