import { CARDINS1, CARDINS2, CARDINS3, CARDINS4, CARDINS5, CARDINS6 } from "../assets/images";

export const newCardItems = [
    {
        type : 'card-issuance',
        cardtitle : 'Corporate Cards',
        image : CARDINS1,
        content: "Corporate card, company card, company credit card, corporate business card, purchase card – they’re all names for essentially the same thing. ",
        bg: '#B1DCFA',
        cardlink : "/card-issuance/coporate-cards"
    },
    {
        type : 'card-issuance',
        cardtitle : 'Multi Currency Travel Card',
        image : CARDINS2 ,
        content: "A Multi-Currency Travel Card is a prepaid card that allows travelers to load multiple foreign currencies onto a single card. ",
        bg: '#FEEAB4',
        cardlink : "/card-issuance/multi-currency-travel-card"

    },
    {
        type : 'card-issuance',
        cardtitle : 'Accupayd - Giftcards',
        image : CARDINS3,
        content: " Accupayd - Giftcards are prepaid cards or vouchers that hold a specific monetary value and are often issued by retailers or businesses as a form of gift or promotional offering. ",
        bg: '#B1DCFA',
        cardlink : "/card-issuance/gift-card"
        
    },
    {
        type : 'card-issuance',
        cardtitle : 'GPR Cards',
        image : CARDINS4,
        content: 'A reloadable prepaid card allows you to add more money. This type of card called as General Purpose Reloadable Card, or GPR Card. ',
        bg: '#FEEAB4',
        cardlink : "/card-issuance/gpr-card"
    },
    {
        type : 'card-issuance',
        cardtitle : 'Mobility Cards',
        image : CARDINS5,
        content: 'Each city or transportation system may have its own specific mobility card program with unique features and benefits. ',
        bg: '#B1DCFA',
        cardlink : "/card-issuance/mobility-card"
    },
    {
        type : 'card-issuance',
        cardtitle : 'Virtual Cards',
        image : CARDINS6,
        content: 'Virtual cards are digital payment cards that exist only in a digital or electronic form and do not have a physical card counterpart. ',
        bg: '#FEEAB4',
        cardlink : "/card-issuance/virtual-card"
    },
]