import React, {useState} from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { styled } from 'styled-components';
import FeaturedCard from '../featured-card/featuredCard';
import { MicroATM } from '../../utils/MicroAtm';
import { BillPayment } from '../../utils/BillPayment';
import { CoporateCard } from '../../utils/CoporateCardsContent';
import { PrepaidRecharges } from '../../utils/prepaid-recharges';
import { SafeGold } from '../../utils/SafeGold';
import { NeoBanking } from '../../utils/NeoBanking';
import { Mulicard } from '../../utils/MulticardCotent';
import { GiftCard } from '../../utils/GiftcardContent';
import { GprCard } from '../../utils/GprCardContent';
import { MobilityCard } from '../../utils/MobilitycardContent';
import { VirtualCard } from '../../utils/VirtualCardContent';
import { EMT } from '../../utils/emt';
import { PayRentCard } from '../../utils/PayRentcontent';
import { MobileDthContent } from '../../utils/MobileDthContent';
import { AaadharContent } from '../../utils/AadhaarpayConent';
import { PrepaidContent } from '../../utils/PrepaidContent';
import { CredicardContent } from '../../utils/CreditcardContent';
import { UtilityBillsContent } from '../../utils/UtilityBills';

export const FeaturedBenefits = ({title,content,routeName}) => {
const [route, setRoute] = useState(routeName)
const Array=(routeName==='Micro ATM')? MicroATM : (routeName==='EMT') ? EMT :(routeName==='Bill Payments')? BillPayment : (routeName==='Prepaid Recharges') ? PrepaidRecharges : (routeName === 'Safe Gold') ? SafeGold :(routeName === 'Neo Banking') ? NeoBanking : (routeName==='copratecard') ? CoporateCard   :  (routeName==='multitravelcard') ? Mulicard : (routeName==='giftcard') ? GiftCard : (routeName==='gprcard') ? GprCard : (routeName==='mobilitycard') ? MobilityCard :  (routeName==='virtualcard') ? VirtualCard :  (routeName==='payrent') ? PayRentCard : (routeName==='mobiledth') ? MobileDthContent : (routeName==='aadhaarpay') ? AaadharContent : (routeName==='prepaid') ? PrepaidContent : (routeName==='creditcard') ? CredicardContent : (routeName==='prepaid') ? PrepaidContent : (routeName==='utilitybills') ? UtilityBillsContent : [];

const HeaderText = styled.h1`
font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 1))), 40px);
color: rgba(14, 10, 0, 0.85);
font-family: 'Open Sans', sans-serif;
font-weight: 800;
text-align: center;
`
const HeaderContent = styled.p`
font-size: min(max(14px, calc(0.875rem + ((1vw - 3.2px) * 0.25))), 16px);
color: rgba(14, 10, 0, 0.85);
font-family: 'Open Sans', sans-serif;
font-weight: 600;
text-align: center;
margin: 1rem auto;
`
return (
<>
<Container  className='mt-175 ' id='scroll_section'>
   <Row>
      <Col>
      <div className='card_layout_header'>
         <HeaderText>{title}</HeaderText>
         <Row>
         <Col  lg={{span: 8, offset: 2 }}>
         <HeaderContent>{content}</HeaderContent>
         </Col>
         </Row>
       
      </div>
      </Col>
   </Row>
   <div className='mt-30'>
      <Row>
         {
         Array.map((item, index) =>{
         return(
         <Col key={index} lg={6} md={12} sm={12} xs={12} className='mb-25'>
         <FeaturedCard title={item.title} content = {item.content} />
         </Col>
         )
         })
         }
      </Row>
   </div>
</Container>
</>
)
}