import React, { useEffect } from 'react'
import PageBanner from '../../components/page-banner/PageBanner'
import { Service_img8 } from '../../assets/images'
import { SloveFor } from '../../components/slovefor/SloveCard'
import GetinTouch from '../../components/get-in-touch/GetinTouch'
import FooterBankingServices from '../../components/footer-banking-services/FooterBankingServices'
import CommomBanner from '../../components/commonbanner/CommomBanner'
import { FeaturedBenefits } from '../../components/featured-benefits/featured-benefits'

const NeoBanking = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
       <div className='subpage_header'>
       <PageBanner
        breadcrumb={"Banking"}
        breadcrumb2={"Neo Banking"}
        title={"Neo Banking"}
        preLink= {'/banking'}
        content={"Neo banking, also known as digital banking or challenger banking, refers to a new breed of financial institutions that operate exclusively online and offer innovative and customer-centric banking solutions."}
        image={Service_img8} />
       </div>
     
        <FeaturedBenefits title="Featured Benefits" content="Neo banking, also known as digital banking or challenger banking, refers to the emergence of fintech companies and startups that offer banking services entirely through digital channels." routeName="Neo Banking"/>
        <CommomBanner  title={"Get the Neo Banking for Your Needs"}/>
        <SloveFor routeName="Neo Banking" />
        
        <FooterBankingServices />
        <GetinTouch />
        
       
    </div>
  )
}

export default NeoBanking