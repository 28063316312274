import { CARDINS1, CARDINS2 , CARDINS3, CARDINS4, CARDINS5, CARDINS6,BANKING_1, BANKING_2 ,BANKING_3, BANKING_4, BANKING_5, BANKING_6, PAYMENT1 , PAYMENT2, PAYMENT3, PAYMENT4,PAYMENT5 ,PAYMENT6  } from "../assets/images";



export const cardItems = [
    {
        type : 'card-issuance',
        cardtitle : 'Corporate Cards',
        image : CARDINS1,
        content: "Corporate card, company card, company credit card, corporate business card, purchase card – they’re all names for essentially the same thing. ",
        bg: '#B1DCFA',
        cardlink : "/card-issuance/coporate-cards"
    },
    {
        type : 'card-issuance',
        cardtitle : 'Multi Currency Travel Card',
        image : CARDINS2,
        content: "A Multi-Currency Travel Card is a prepaid card that allows travelers to load multiple foreign currencies onto a single card. ",
        bg: '#FEEAB4',
        cardlink : "/card-issuance/multi-currency-travel-card"

    },
    {
        type : 'card-issuance',
        cardtitle : 'Accupayd - Giftcards',
        image : CARDINS3,
        content: "Accupayd - Giftcards are prepaid cards or vouchers that hold a specific monetary value and are often issued by retailers or businesses as a form of gift or promotional offering. ",
        bg: '#B1DCFA',
        cardlink : "/card-issuance/gift-card"
        
    },
    {
        type : 'card-issuance',
        cardtitle : 'GPR Cards',
        image : CARDINS4,
        content: 'A reloadable prepaid card allows you to add more money. This type of card called as General Purpose Reloadable Card, or GPR Card.',
        bg: '#FEEAB4',
        cardlink : "/card-issuance/gpr-card"
    },
    {
        type : 'card-issuance',
        cardtitle : 'Mobility Cards',
        image : CARDINS5,
        content: 'Each city or transportation system may have its own specific mobility card program with unique features and benefits. ',
        bg: '#B1DCFA',
        cardlink : "/card-issuance/mobility-card"
    },
    {
        type : 'card-issuance',
        cardtitle : 'Virtual Cards',
        image : CARDINS6,
        content: 'Virtual cards are digital payment cards that exist only in a digital or electronic form and do not have a physical card counterpart. ',
        bg: '#FEEAB4',
        cardlink : "/card-issuance/virtual-card"
    },
    {
        type : 'banking',
        cardtitle : 'Micro ATM',
        image : BANKING_1,
        content: "Micro ATMs are likely to evolve and provide even more advanced and comprehensive banking services in the future. ",
        bg: '#B1DCFA',
        cardlink: '/banking/micro-atm'
    },
    {
        type : 'banking',
        cardtitle : 'Electronic Fund Transfer',
        image : BANKING_2,
        content: "Instant money transfer refers to the swift and real-time transfer of funds from one account to another, typically facilitated through digital payment systems and electronic platforms. ",
        bg: '#FEEAB4',
        cardlink: '/banking/electronic-money-transfer'

    },
    {
        type : 'banking',
        cardtitle : 'Bill Payments',
        image : BANKING_3,
        content: "Bill payments refer to the process of settling financial obligations or expenses by making payments to service providers or creditors for various services or products used. ",
        bg: '#B1DCFA',
        cardlink: '/banking/bill-payment'
        
    },
    {
        type : 'banking',
        cardtitle : 'Prepaid Recharges',
        image : BANKING_4,
        content: 'These prepaid services are popular for their convenience, as users can pay in advance and use the services until the prepaid balance is exhausted or the validity period expires. ',
        bg: '#FEEAB4',
        cardlink: '/banking/prepaid-recharges'
    },
    {
        type : 'banking',
        cardtitle : 'Neo Banking',
        image : BANKING_5,
        content: "Customers considering using a neo bank should research the bank's background, security measures, and any applicable fees or limitations before opening an account. ",
        bg: '#B1DCFA',
        cardlink: '/banking/neo-banking'
    },
    {
        type : 'banking',
        cardtitle : 'Safe Gold',
        image : BANKING_6,
        content: 'The concept of safe gold aims to combine the traditional value and stability of gold with the convenience and accessibility of digital assets. ',
        bg: '#FEEAB4',
        cardlink: '/banking/safe-gold'
    },
    {
        type : 'payments',
        cardtitle : 'Pay Rent',
        image : PAYMENT1,
        content: "Paying rent on time is essential to maintaining a good relationship with your landlord and ensuring you have a stable place to live. ",
        bg: '#B1DCFA',
        cardlink : "/payment-services/pay-rent"
    },
    {
        type : 'payments',
        cardtitle : 'Mobile and DTH Recharge',
        image : PAYMENT2,
        content: "Online recharge platforms and mobile apps have made the process quick, easy, and convenient, allowing users to recharge from the comfort of their homes or on the go. ",
        bg: '#FEEAB4',
        cardlink : "/payment-services/mobile-dth-recharge"

    },
    {
        type : 'payments',
        cardtitle : 'Aadhaar Pay',
        image : PAYMENT3,
        content: "Aadhaar Pay is a digital payment system introduced by the Government of India to promote cashless transactions and financial inclusion. ",
        bg: '#B1DCFA',
        cardlink : "/payment-services/aadhaar-pay"
        
    },
    {
        type : 'payments',
        cardtitle : 'Prepaid Card',
        image : PAYMENT4,
        content: 'A prepaid card is a type of payment card that allows users to make purchases or transactions using the funds preloaded onto the card. ',
        bg: '#FEEAB4',
        cardlink : "/payment-services/prepaid-card"
    },
    {
        type : 'payments',
        cardtitle : 'Credit Card Bills',
        image : PAYMENT5,
        content: "These bills provide a detailed breakdown of the purchases, payments, interest charges, fees, and any other transactions associated with the credit card account. ",
        bg: '#B1DCFA',
        cardlink : "/payment-services/creditcard-bills"
    },
    {
        type : 'payments',
        cardtitle : 'Utility Bills',
        image : PAYMENT6,
        content: 'Utility bills refer to the invoices or statements issued by utility companies for the services they provide to residential or commercial customers. ',
        bg: '#FEEAB4',
        cardlink : "/payment-services/utility-bills"
    },



]