import React, { useEffect, useState , useLayoutEffect, useRef} from 'react'
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { styled } from 'styled-components';
import { ANIMATIONBANNER_1, ANIMATIONBANNER_2, BANNERBG, PAYMENTSHOMEBANNER, Scroll } from '../../assets/images';
import './herobanner.css'
import Slider from 'react-slick';



const HeroBanner = () => {

    const [dummy, setdummy] = useState("Card Insurance");
    const caro = useRef(null)
    const [currentIndex, setCurrentIndex] = useState(0);

    useLayoutEffect(() => {
        setInterval(() => {
            gotoNext()
        }, 2000);
    //   console.log(caro.current)
      return () => {}
    }, [])

    const gotoNext = () => {
        caro.current?.slickNext();
        }



    useEffect(() => {
        if (dummy == "Card Insurance") {
          setTimeout(() => {
            setdummy("Banking")
            console.log("bye")
          }, 2500);
        }
        if (dummy == "Banking") {
          setTimeout(() => {
            setdummy("Card Insurance")
            console.log("Hi")
          }, 2500);
        }
      }, [dummy])
    

      const Image = {
        dots: false,
        infinite: true,
      //   speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 0,
        arrows: false,
        fade: true,
        draggable:false
    };

    
   

    const BannerMaintext = styled.h1 `
 font-size: min(max(20px, calc(1.25rem + (48 - 20) * ((100vw - 320px) / (1400 - 320)))), 48px);
    color: #0e0a00d6;
    font-family: 'Open Sans', sans-serif;
    font-family: 'Merriweather Sans', sans-serif;
    font-weight: 900;
    
    `
    const ConnectButton = styled.button`
    background: linear-gradient(90deg, rgba(243,154,52,1) 0%, rgba(252,199,58,1) 50%);
     color: #0E0A00;
     font-family: 'Open Sans', sans-serif;
     font-weight: 700;
     border: none;
     padding: 8px 24px !important;
    margin-top: 20px;
    font-size: min(max(14px, calc(0.875rem + (20 - 14) * ((100vw - 320px) / (1400 - 320)))), 20px);
     border-radius: 5px;

     &:hover{
      background: #0E0A00;
        color: #fff !important;
     }
 
 `



  return (
    <>
    <section className='hero_banner_section'>
   <Container>
      <Row>
         <Col className='position-relative'>
         <div className='hero_banner' >
            <Row>
               <Col lg='6' >
               <div className='hero_banner_content'>
                  <BannerMaintext>Your Trusted FinTech  Partner For </BannerMaintext>
                  <div className='animation_list'>
                     <h2  className={currentIndex ===0 ? 'animation_text' : 'd-none'} >Card Issuance </h2> 
                     <h2  className={currentIndex ===1 ? 'animation_text' : 'd-none'} >Banking</h2> 
                     <h2  className={currentIndex ===2 ? 'animation_text' : 'd-none'} >Payment Services</h2> 
                  </div>
                  <p className='hero_banner_content_text'>Our custom APIs will spice up Banks, fintech, and other <br /> businesses to provide a vast range of financial solutions.</p>
                  <Link to="/kickstart">
                  <ConnectButton>kickstart</ConnectButton>
                  </Link>
                  
               </div>
               </Col>
               <Col lg='6' >
               <div className='hero_banner_bg text-end'>
                  <img className='img-fluid bg' src={BANNERBG} alt="photo" />
                  {/* <img className='img-fluid bg' src={BANNERBG} alt="photo" />
                  < div className='animation_img'>
                  <img className='ani_imge' src={ANIMATIONBANNER_1} alt="pic" />
               </div>
               <div className='animation_img second'>
                  <img className='ani_imge' src={ANIMATIONBANNER_2} alt="pic" />
               </div>
               */}
               <div className=' slider_back' >
                  <Slider    ref={caro} {...Image} 
                  beforeChange={(currentSlide, nextSlide) => {
                  setCurrentIndex(nextSlide);
                  console.log("currentSlide", currentSlide)
                  }}>
                  <div className='animation_img'>
                     <img className='ani_imge' src={ANIMATIONBANNER_1} alt="pic" />
                  </div>
                  <div className='animation_img second'>
                     <img className='ani_imge' src={ANIMATIONBANNER_2} alt="pic" />
                  </div>
                  <div className='animation_img second'>
                     <img className='ani_imge' src={PAYMENTSHOMEBANNER} alt="pic" />
                  </div>
                  </Slider> 
               </div>
         </div>
         </Col>
         </Row>
        
         </div>
         <div className='scroll_arow'>
       <a href="#card-issuance"><img src={Scroll} alt="scroll" /></a>
       </div>
         </Col>
      </Row>
     
      
    
   </Container>
</section>



        
    </>
  )
}

export default HeroBanner