import React, { useLayoutEffect } from 'react'
import ProductAccordian from '../product-accordian/ProductAccordian'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { styled } from 'styled-components';
import { BANKINGACBG, CARDINSSUANCEIMG, PAYMENTACBG } from '../../assets/images';
import ProductAccordianBank from '../productAccordianBank/ProductAccordianBank';
import ProductPaymentAcc from '../ProductAccordianPayment/ProductPaymentAcc';



const Smallcontet = styled.p `

color: #0E0A00BF;
font-family: 'Open Sans', sans-serif;
font-weight: 600;
font-size: min(max(15px, calc(0.9375rem + (18 - 15) * ((100vw - 320px) / (1400 - 320)))), 18px);
line-height: 28px;
`
const PaymentAccord = () => {

 return (

<>
    <Container className='mt-175 mb-110' id='scroll_section'>
        <Row>
          <Smallcontet> <b>Payment services </b>  refer to the various methods and platforms that facilitate the transfer of money or funds between individuals, businesses, and organizations. These services play a crucial role in enabling financial transactions, both online and offline, in a secure, convenient, and efficient manner.
          </Smallcontet>
          </Row>
          <div className='mt-50 '>
            <Row>
            <Col lg={6} style={{alignSelf: 'center'}}>
        <img className='img-fluid' src={PAYMENTACBG} alt="card" />
        </Col>
        <Col lg={6}>
           <ProductPaymentAcc  />
        </Col>
      </Row>
          </div>

    </Container>



      

        
    </>
  )
}

export default PaymentAccord