import React, { useEffect } from 'react'
import PageBanner from '../../../components/page-banner/PageBanner';
import { FeaturedBenefits } from '../../../components/featured-benefits/featured-benefits';
import CommomBanner from '../../../components/commonbanner/CommomBanner';
import { SloveFor } from '../../../components/slovefor/SloveCard';
import FooterPaymentsServices from '../../../components/footer-payments-services/FooterPaymentsServices';
import GetinTouch from '../../../components/get-in-touch/GetinTouch';
import { UTILITYBILLS } from '../../../assets/images';

const UtilityBills = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      return (
        <>
        <div className='subpage_header'>
        <PageBanner
            breadcrumb={"Payment Services"}
            breadcrumb2={"Utility bills"}
            title={"Utility bills"}
            preLink= {'/payment-services'}
            content={`Utility bills are regular invoices or statements that individuals or businesses receive from utility service providers for the consumption of essential services such as electricity, water, gas, and sometimes other services like internet and cable TV.`}
            image={UTILITYBILLS} />
        </div>
         <FeaturedBenefits title="Featured Benefits" content="Utility bills, which include bills for services like electricity, water, gas, and internet, offer several benefits for consumers and utility providers alike." routeName="utilitybills" />
        <CommomBanner title={"Get the Mobile and DTH Recharge for your needs"}  />
        <SloveFor   routeName="utilitybills"  />
        <FooterPaymentsServices />
        <GetinTouch />
        </>
      )
}

export default UtilityBills