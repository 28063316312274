import React from 'react'
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { styled } from 'styled-components';

const BannerSection = styled.div`
    
    background: #FEEAB4;
    width: 100%;
    padding: 50px;
    @media screen and (max-width:991px) {
      height: 100%;
      padding: 15px;
      
    }
`
const BannerText = styled.h1`
color: rgba(14, 10, 0, 0.85);
font-family: 'Open Sans', sans-serif;
font-weight: 800;
font-size: min(max(18px, calc(1.125rem + (48 - 18) * ((100vw - 320px) / (1400 - 320)))), 48px);
`
const BannerContent = styled.p`
color: rgba(14, 10, 0, 0.75);
font-family: 'Open Sans', sans-serif;
font-weight: 600;
font-size: min(max(14px, calc(1.125rem + (18 - 14) * ((100vw - 320px) / (1400 - 320)))), 18px);
width: 45%;
margin: auto;
`

const SubmitBtn = styled.button`
        background: linear-gradient(90deg, rgba(243,154,52,1) 0%, rgba(252,199,58,1) 50%);
        color: #0E0A00;
        font-family: 'Open Sans',sans-serif;
        font-weight: 700;
        border: none;
        padding: 8px 26px;
        font-size: min(max(14px, calc(0.875rem + (20 - 14) * ((100vw - 320px) / (1400 - 320)))), 20px);
        border-radius: 5px;
        margin-top: 20px;
        
        &:hover{
      background: #0E0A00;
        color: #fff !important;
     }
     `
const AboutBanner = () => {
  return (
    <>
    <BannerSection className='text-center'>
     <Container fluid>
       <BannerText>We are Flexible to your needs</BannerText>
       <BannerContent className='width-remove'>Innovate, launch and scale card programs that suit your business needs.Let’s talk about your use case and how we can help.</BannerContent>
       <Link to="/kickstart"><SubmitBtn>Kickstart</SubmitBtn></Link>
      </Container>
    </BannerSection>
    </>
  )
}

export default AboutBanner