import React, { useEffect } from 'react'
import PageBanner from '../../components/page-banner/PageBanner'
import { Service_img2 } from '../../assets/images'
import { SloveFor } from '../../components/slovefor/SloveCard'
import { WhyAccupayd } from '../../components/why-accupayd/whyAccupayd'
import GetinTouch from '../../components/get-in-touch/GetinTouch'
import BankingAccord from '../../components/BankingAccord/BankingAccord'
import FooterBankingServices from '../../components/footer-banking-services/FooterBankingServices'
import CommomBanner from '../../components/commonbanner/CommomBanner'

const BankingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <PageBanner
        breadcrumb={"Banking"}
        title={"Banking"}
        content={"Banking payment services refer to the various methods and systems provided by banks to facilitate the transfer of funds between individuals, businesses, and other entities. These services play a crucial role in enabling seamless financial transactions and managing money effectively."}
        image={Service_img2} />
        <BankingAccord />
        <SloveFor title={'We Solve For'} routeName="Banking" content = "Industries across the board use our card solutions for diverse requirements. You name it, we've powered it!" />
        <CommomBanner title={"Get the Banking for Your Needs"} />
        <WhyAccupayd title="Why Accupayd?" />
        <GetinTouch />
        <FooterBankingServices />
       
    </div>
  )
}

export default BankingPage