import React from 'react'
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { styled } from 'styled-components';
import { COMMONBANNERBG } from '../../assets/images';

const BannerSection = styled.div`
    
    height: 350px;
    background: #FEEAB4;
    width: 100%;
    padding: 30px;
    @media screen and (max-width:991px) {
      height: 100%;
      padding: 15px;
      
    }
`
const BannerText = styled.h1`
color: #0e0a00;
font-family: 'Open Sans', sans-serif;
font-weight: 800;
font-size: min(max(18px, calc(1.125rem + (40 - 18) * ((100vw - 320px) / (1400 - 320)))), 40px);
`

const SubmitBtn = styled.button`
        background: linear-gradient(90deg, rgba(243,154,52,1) 0%, rgba(252,199,58,1) 50%);
        color: #0E0A00;
        font-family: 'Open Sans',sans-serif;
        font-weight: 700;
        border: none;
        padding: 8px 26px;
        font-size: min(max(14px, calc(0.875rem + (20 - 14) * ((100vw - 320px) / (1400 - 320)))), 20px);
        border-radius: 5px;
        margin-top: 20px;
        
        &:hover{
      background: #0E0A00;
        color: #fff !important;
     }
     `
const CommomBanner = ({title}) => {
  return (
    <>
     <BannerSection className='mt-175'>
    <Container fluid>
       
        <Row className='p-5 responsive_banner_padding'>
            <Col className='w-45-lg' lg={5} style={{alignSelf:'center'}}>
                
                    <BannerText>{title}</BannerText>
                    <Link to="/kickstart">
                    <SubmitBtn>Kickstart</SubmitBtn>
                    </Link>
                    
                    </Col>
            <Col lg={6} className='position-relative'>
             <img className='img-fluid commonbanner-img' src={COMMONBANNERBG} alt="" />
                </Col>
                
        </Row>
      </Container>

    </BannerSection>
        
    </>
  )
}

export default CommomBanner