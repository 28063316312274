import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import { styled } from 'styled-components'
import { IAS_1, IAS_2, IAS_3, Portrait_man } from '../../assets/images'

const NowYourTurn = () => {

    const Section1 = styled.section`
    background: #1072B8;
`

    const SecTitle = styled.h1`
     font-size: min(max(20px, calc(0.875rem + (40 - 20) * ((100vw - 320px) / (1400 - 320)))), 40px);
     font-family: 'Open Sans', sans-serif;
     font-weight: 800;
     color: #fff;
     margin-bottom: 40px;
`
    const SecContent = styled.p`
    font-size: min(max(14px, calc(0.875rem + (16 - 14) * ((100vw - 320px) / (1400 - 320)))), 16px);
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    opacity: 85%;
    line-height: 26px;
`
    const StartBtn = styled.button`
    background: linear-gradient(90deg, rgba(243,154,52,1) 0%, rgba(252,199,58,1) 50%);
    color: #0E0A00;
    font-family: 'Open Sans',sans-serif;
    font-weight: 700;
    border: none;
    padding: 8px 26px;
    font-size: min(max(14px, calc(0.875rem + (20 - 14) * ((100vw - 320px) / (1400 - 320)))), 20px);
    border-radius: 5px;
    &:hover{
      background: #fff;
        color: #0E0A00 !important;
     }
    `

    const Section2 = styled.section`
        background: #fffdfa;
    `
    const Imgsec = styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `

    return (
        <>
            <Section1 className='mt-130'>
                <Container>
                    <Row className='py-5'>
                        <Col lg={4}>
                            <SecTitle>Now it’s your turn</SecTitle>
                            {/* <SecContent>
                                Lorem ipsum dolor sit amet consectetur. Sodales enim nisl morbi arcu.
                                Aliquam luctus orci cras lacinia ac risus. Commodo ut at sed egestas.
                                Aliquet eget tristique leo consectetur pulvinar faucibus pharetra.
                            </SecContent> */}
                            <Link to="/kickstart">
                            <StartBtn>Kickstart</StartBtn>
                            </Link>
                            
                        </Col>
                        <Col lg={8}></Col>
                    </Row>
                </Container>
            </Section1>
            <Section2>
                <Container>
                    <Row className='py-5 position-relative'>
                        <Col lg={5} md={8} xl={4}>
                            <Imgsec>
                            <img className='ias-img1' src={IAS_1} alt="ias-img" />
                            <img className='ias-img2' src={IAS_2} alt="ias-img" />
                            <img className='ias-img3' src={IAS_3} alt="ias-img" />
                            </Imgsec>
                        </Col>
                        <Col lg={7} md={4} xl={8}>
                            <img className='portrait-man' src={Portrait_man} alt="" />
                        </Col>
                    </Row>
                </Container>
            </Section2>
        </>
    )
}

export default NowYourTurn