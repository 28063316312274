import React, { useEffect } from 'react'
import { CardLayout } from '../../components/cardlayout/CardLayout'
import { Banking } from '../../components/Banking/Banking'
import { Payments } from '../../components/PaymentService/Payments'
import { CountSection } from '../../components/count-sec/CountSec'
import { WhyAccupayd } from '../../components/why-accupayd/whyAccupayd'
import NowYourTurn from '../../components/now-your-turn/NowYourTurn'
import GetinTouch from '../../components/get-in-touch/GetinTouch'
import HeroBanner from '../../components/herobanner/HeroBanner'

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>

       <HeroBanner/>
       <CardLayout title={'Card Issuance'} title2={'Banking'} title3={'Payment Services'} />
       {/* <Banking title={'Banking'}  /> */}
       {/* <Payments title={'Payment Services'} /> */}
       <CountSection />
       <WhyAccupayd title="Why Accupayd?" />
       <GetinTouch />
       <NowYourTurn />
    </div>
  )
}

export default Home