import { COPRATECARD , PAYMENT1 , PAYMENT2, PAYMENT3, PAYMENT4,PAYMENT5 ,PAYMENT6 } from "../assets/images";



export const PaymentDetails = [
    {
        cardtitle : 'Pay Rent',
        image : PAYMENT1,
        content: "Paying rent on time is essential to maintaining a good relationship with your landlord and ensuring you have a stable place to live. ",
        bg: '#B1DCFA',
        cardlink : "/payment-services/pay-rent"
    },
    {
        cardtitle : 'Mobile and DTH Recharge',
        image : PAYMENT2,
        content: "Online recharge platforms and mobile apps have made the process quick, easy, and convenient, allowing users to recharge from the comfort of their homes or on the go. ",
        bg: '#FEEAB4',
        cardlink : "/payment-services/mobile-dth-recharge"

    },
    {
        cardtitle : 'Aadhaar Pay',
        image : PAYMENT3,
        content: "Aadhaar Pay is a digital payment system introduced by the Government of India to promote cashless transactions and financial inclusion. ",
        bg: '#B1DCFA',
        cardlink : "/payment-services/aadhaar-pay"
        
    },
    {
        cardtitle : 'Prepaid Card',
        image : PAYMENT4,
        content: 'A prepaid card is a type of payment card that allows users to make purchases or transactions using the funds preloaded onto the card. ',
        bg: '#FEEAB4',
        cardlink : "/payment-services/prepaid-card"
    },
    {
        cardtitle : 'Credit Card Bills',
        image : PAYMENT5,
        content: "These bills provide a detailed breakdown of the purchases, payments, interest charges, fees, and any other transactions associated with the credit card account. ",
        bg: '#B1DCFA',
        cardlink : "/payment-services/creditcard-bills"
    },
    {
        cardtitle : 'Utility Bills',
        image : PAYMENT6,
        content: 'Utility bills refer to the invoices or statements issued by utility companies for the services they provide to residential or commercial customers. ',
        bg: '#FEEAB4',
        cardlink : "/payment-services/utility-bills"
    },


]