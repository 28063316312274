import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { styled } from 'styled-components';
import ServicesCard from '../servicecard/ServicesCard';


const FooterPaymentsServices = () => {
  return (

<Container className='mt-110'>
    <Row>
        <Col lg={6} className='common_card_gap'>

        <ServicesCard title ="Card Issuance" content = "Banking services encompass a wide range of financial products and facilities offered by banks to individuals, businesses, and governments. These services are designed to meet various financial needs and help customers manage their money effectively. Here are some common banking services" 
        btn= "More About Card Issuance" 
        link = "/card-issuance"  />


        </Col>
        <Col lg={6} className='common_card_gap'>

<ServicesCard title ="Banking" content = "Banking services encompass a wide range of financial products and facilities offered by banks to individuals, businesses, and governments. These services are designed to meet various financial needs and help customers manage their money effectively. Here are some common banking services" 
link = "/banking"  btn= "More About Banking"   />


</Col>

    </Row>
</Container>

  )
}

export default FooterPaymentsServices