import React, { useLayoutEffect } from 'react'
import ProductAccordian from '../product-accordian/ProductAccordian'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { styled } from 'styled-components';
import { BANKINGACBG, CARDINSSUANCEIMG } from '../../assets/images';
import ProductAccordianBank from '../productAccordianBank/ProductAccordianBank';



const Smallcontet = styled.p `

color: #0E0A00BF;
font-family: 'Open Sans', sans-serif;
font-weight: 600;
font-size: min(max(15px, calc(0.9375rem + (18 - 15) * ((100vw - 320px) / (1400 - 320)))), 18px);
line-height: 28px;
`
const BankingAccord = () => {

 return (

<>
    <Container className='mt-175 mb-110' id='scroll_section'>
        <Row>
          <Smallcontet> <b>Banking </b>  The offerings may vary based on the bank and the region, and with the advancement of technology, new payment methods and services continue to emerge, making financial transactions more convenient and secure for customers.</Smallcontet>
          </Row>
          <div className='mt-50 '>
            <Row>
            <Col lg={6} style={{alignSelf: 'center'}}>
        <img className='img-fluid' src={BANKINGACBG} alt="card" />
        </Col>
        <Col lg={6}>
           <ProductAccordianBank  />
        </Col>
      </Row>
          </div>

    </Container>



      

        
    </>
  )
}

export default BankingAccord