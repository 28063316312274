import React from 'react'
import {Link} from 'react-router-dom';
import CommonCard from '../common-cards/CommonCard'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { styled } from 'styled-components';
import { cardItems } from '../../utils/CardIssuance';


const MainTitle = styled.h1`
  font-size: min(max(22px, calc(1.375rem + (40 - 22) * ((100vw - 320px) / (1400 - 320)))), 40px);
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  margin-bottom: 30px;
  color: #0E0A00;
  opacity: 85%;
  padding-top: 30px;
`


    const HeadeText = styled.h1`
font-size: min(max(22px, calc(1.375rem + (32 - 22) * ((100vw - 320px) / (1400 - 320)))), 32px);
    color: #1072B8;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    position: relative;
    padding-left: 16px;

  
    &:after {
    content: " ";
    height: 100%;
    width: 6px;
    background: #F6AB36;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 20px;

  }
    
    `


const ConnectButton = styled.button`
   background: linear-gradient(90deg, rgba(243,154,52,1) 0%, rgba(252,199,58,1) 50%);
    color: #0E0A00;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    border: none;
   padding: 10px 26px;
   font-size: min(max(12px, calc(0.75rem + (20 - 12) * ((100vw - 320px) / (1400 - 320)))), 20px);
    border-radius: 5px;
    &:hover{
      background: #0E0A00;
        color: #fff !important;
     }
`
const MoreButton = styled.button`
  
    color: #0E0A00;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    border: 2px solid #000;
   padding: 10px 15px;
   font-size: min(max(12px, calc(0.75rem + (20 - 12) * ((100vw - 320px) / (1400 - 320)))), 20px);
    background: transparent;
    border-radius: 5px;

    &:hover{
      background: #0E0A00;
        color: #fff !important;
        border: 2px solid transparent;
     }
     @media screen and (max-width : 991px) {
      padding: 10px ;
      
     }
`

export const CardLayout = ({title, title2, title3}) => {

  const CardIssuanceArray =  cardItems.filter((items)=> items.type == 'card-issuance');
  const BankingArray =  cardItems.filter((items)=> items.type == 'banking')
  const PaymentsArray =  cardItems.filter((items)=> items.type == 'payments')
 

return (
  <>
<div className='position-relative' id='card-issuance'>
   <Container  className='mt-75'>
      <MainTitle>Explore Our Products</MainTitle>
      <Row>
         <Col>
         <div className='card_layout_header'>
            <HeadeText>{title}</HeadeText>
         </div>
         </Col>
      </Row>
      <div className='mt-30'>
         <Row>
            {  
            CardIssuanceArray.map((item, index) =>{
            return(
            <Col key={index} lg={4} md={6} className='common_card_gap card_layout'>
            <CommonCard cardtitle={item.cardtitle} content = {item.content} image = {item.image} bg ={item.bg} cardlink = {item.cardlink}  />
            </Col>
            )
            })
            }
         </Row>
      </div>
      <div className='d-flex align-items-center justify-content-center gap-4 mt-20'>
         <Link to="/kickstart">
         <ConnectButton>Kickstart</ConnectButton>
         </Link>
         <Link to="/card-issuance">
         <MoreButton>More About Card Issuance</MoreButton>
         </Link>
      </div>
   </Container>
   <div className='gradident_circle_green'></div>
   <div className='gradident_circle_red'></div>
</div>
{/* Banking  */}
<div className='position-relative' id='card-issuance'>
   <Container  className='mt-110'>
      <Row>
         <Col>
         <div className='card_layout_header'>
            <HeadeText>{title2}</HeadeText>
         </div>
         </Col>
      </Row>
      <div className='mt-30'>
         <Row>
            {  
            BankingArray.map((item, index) =>{
            return(
            <Col key={index} lg={4} md={6} className='common_card_gap card_layout'>
            <CommonCard cardtitle={item.cardtitle} content = {item.content} image = {item.image} bg ={item.bg} cardlink = {item.cardlink}  />
            </Col>
            )
            }  ) 
            }
         </Row>
      </div>
      <div className='d-flex align-items-center justify-content-center gap-4 mt-20'>
         <Link to="/kickstart">
         <ConnectButton>Kickstart</ConnectButton>
         </Link>
         <Link to="/banking">
         <MoreButton>More About Banking</MoreButton>
         </Link>
      </div>
   </Container>
   <div className='gradident_circle_green'></div>
   <div className='gradident_circle_red'></div>
</div>
{/* payments  */}
<div className='position-relative' id='card-issuance'>
   <Container  className='mt-110'>
      <Row>
         <Col>
         <div className='card_layout_header'>
            <HeadeText>{title3}</HeadeText>
         </div>
         </Col>
      </Row>
      <div className='mt-30'>
         <Row>
            {  
            PaymentsArray.map((item, index) =>{
            return(
            <Col key={index} lg={4} md={6} className='common_card_gap card_layout'>
            <CommonCard cardtitle={item.cardtitle} content = {item.content} image = {item.image} bg ={item.bg} cardlink = {item.cardlink}  />
            </Col>
            )
            }  ) 
            }
         </Row>
      </div>
      <div className='d-flex align-items-center justify-content-center gap-4 mt-20'>
         <Link to="/kickstart">
         <ConnectButton>Kickstart</ConnectButton>
         </Link>
         <Link to="/payment-services">
         <MoreButton>More About Payment Services</MoreButton>
         </Link>
      </div>
   </Container>
   <div className='gradident_circle_green'></div>
   <div className='gradident_circle_red'></div>
</div>
</>
  





  )
}
