import React from 'react'
import AccupaydCard from './../accupayd-card/accupaydCard'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { styled } from 'styled-components';
import { whyaccupaydItems } from '../../utils/WhyAccupayd';
export const WhyAccupayd = ({title}) => {

const HeadeText = styled.h1`
font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 1))), 40px);
color: rgba(14, 10, 0, 0.85);
font-family: 'Open Sans', sans-serif;
font-weight: 800;
`
return (
<>
<Container  className=' mt-110'>
   <Row>
      <Col>
      <div className='card_layout_header'>
         <HeadeText>{title}</HeadeText>
      </div>
      </Col>
   </Row>
   <div className='mt-30'>
      <Row>
         {
         whyaccupaydItems.map((item, index) =>{
         return(
         <Col key={index} lg={6} md={12} sm={12} xs={12} className='mb-25'>
         <AccupaydCard title={item.title} content = {item.content} image = {item.image} name = {item.clsss} />
         </Col>
         )
         })
         }
      </Row>
   </div>
</Container>
</>
)
}