import React from 'react'
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { styled } from 'styled-components';
import { Who_we_are } from '../../assets/images';


const BannerSection = styled.div`
    
    width: 100%;
    padding: 30px;
  
`
const BannerText = styled.h1`
color: #0e0a00;
font-family: 'Open Sans', sans-serif;
font-weight: 800;
font-size: min(max(18px, calc(1.125rem + (40 - 18) * ((100vw - 320px) / (1400 - 320)))), 40px);
`
const BannerContent = styled.p`
color: rgba(14, 10, 0, 0.7);
font-family: 'Open Sans', sans-serif;
font-weight: 400;
font-size: min(max(16px, calc(1.125rem + (18 - 16) * ((100vw - 320px) / (1400 - 320)))), 18px);
line-height: 30px;
`
const BannerContentBold = styled.p`
color: rgba(14, 10, 0, 1);
font-family: 'Open Sans', sans-serif;
font-weight: 800;
font-size: min(max(16px, calc(1.125rem + (18 - 16) * ((100vw - 320px) / (1400 - 320)))), 18px);
line-height: 30px;
`

const WhoWeAre = () => {
  return (
    <>
     <BannerSection className='who-we-are'>
    <Container >
       
        <Row className='responsive_banner_padding'>
            <Col className='w-45-lg' lg={6} md={12}>
                
                    <BannerText style={{marginBottom: '25px',marginTop: '25px'}}>Who we are</BannerText>
<BannerContent>Accupayd Tech Private Limited is a Fintech Company. It was established in March 2020 at Madurai, Tamil Nadu, India. In 2023 Accupayd expanded their branch by opening branch office in Chennai, Tamil Nadu, India. Accupayd has several years of experience in product and project-based development.</BannerContent>
<BannerContent>Accupayd Specialized in SaaS/BaaS/PaaS product called “Vyabari” and related processes involved in software development, Designing, maintenance, technical support, DevOps, and information management including support functions like Human Resource, Administration and Accounts. Technology Service Provider (TSP) and payment facilitator.</BannerContent>
<BannerContent>To carry out the business of banking and retail terminal payment gateway services, providing payment services for Genz customers by innovative means of using Collections and payout services, technology solutions and services for Retail Wholesale Banking needs for banking institutions. Our teams of skilled software developers with in-depth domain knowledge enable us to bring out the most innovative end-to-end solutions and services.</BannerContent>
                   </Col>
            <Col lg={6} md={12} className='text-right'>
            <div className='gradident_circle_green_1'></div>
             <img className='img-fluid ' src={Who_we_are} alt="" width="80%"/>
                </Col>
                
        </Row>
      </Container>

    </BannerSection>
        
    </>
  )
}

export default WhoWeAre