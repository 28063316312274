import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { styled } from 'styled-components';
import ServicesCard from '../servicecard/ServicesCard';


const FooterServices = () => {
  return (

<Container className='mt-110'>
    <Row>
        <Col lg={6} className='common_card_gap'>

        <ServicesCard title ="Banking" content = "Banking services encompass a wide range of financial products and facilities offered by banks to individuals, businesses, and governments. These services are designed to meet various financial needs and help customers manage their money effectively. Here are some common banking services" btn= "More About Banking" 
        link = "/banking"  />


        </Col>
        <Col lg={6} className='common_card_gap'>

<ServicesCard title ="Payment Services" content = "Payment services refer to a variety of financial products and systems that facilitate the transfer of money from one party to another. These services play a crucial role in modern economies, enabling individuals, businesses, and governments to conduct transactions efficiently and securely. Here are some common payment services" btn= "More About Payment Services" 
link = "/payment-services"  />


</Col>

    </Row>
</Container>

  )
}

export default FooterServices