import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { styled } from 'styled-components';
import { IAS_1, IAS_2, IAS_3, Portrait_man } from '../../assets/images'
export const Certified = ({title,content}) => {

const HeaderText = styled.h1`
font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 1))), 40px);
color: rgba(14, 10, 0, 0.85);
font-family: 'Open Sans', sans-serif;
font-weight: 800;
`
const HeaderContent = styled.p`
font-size: min(max(14px, calc(1.5rem + ((1vw - 3.2px) * 1))), 18px);
color: rgba(14, 10, 0, 0.75);
font-family: 'Open Sans', sans-serif;
font-weight: 600;
`
const ImageDiv = styled.div`
background: rgba(239, 249, 255, 1);
width: 211px;
height: 192px;
display: flex;
align-items: center;
justify-content: center;
margin: 25px;
`


return (
<>
<Container  className='text-center mt-110'>
   <Row>
      <Col>
      <div className='card_layout_header '>
         <HeaderText>Certified</HeaderText>
         <HeaderContent className='w-50 m-auto'>Lorem ipsum dolor sit amet consectetur. Augue nam quis ut nisl convallis. Faucibus cras platea ut pellentesque gravida et.</HeaderContent>
      </div>
      </Col>
   </Row>
   <div className='mt-30 d-flex justify-content-center'>
      <ImageDiv>
      <img  src={IAS_1} alt={title} width="120px" height="131px"/>
      </ImageDiv>
      <ImageDiv>
      <img  src={IAS_2} alt={title} width="155px" height="100px"/>
      </ImageDiv>
      <ImageDiv>
      <img  src={IAS_3} alt={title} width="95px" height="138px"/> 
      </ImageDiv>
   </div>
</Container>
</>
)
}