import React, { useEffect } from 'react'
import { CardLayout } from '../../components/cardlayout/CardLayout'
import { Banking } from '../../components/Banking/Banking'
import { Payments } from '../../components/PaymentService/Payments'
import { CountSection } from '../../components/count-sec/CountSec'
import { WhyAccupayd } from '../../components/why-accupayd/whyAccupayd'
import PageBanner from '../../components/page-banner/PageBanner'
import GetinTouch from '../../components/get-in-touch/GetinTouch'
import { OurTeam } from '../../components/our-team/ourTeam'
import AboutBanner from '../../components/about-banner/AboutBanner'
import { Certified } from '../../components/certified/certified'
import WhoWeAre from '../../components/who-we-are/WhoWeAre'
import { About_img } from '../../assets/images'
const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='about'>
 <PageBanner
        breadcrumb={"About Us"}
        routeName="about-us"
        title={"India’s most innovative & comprehensive fintech platform"}
        content={"Accupayd embeds fintech in core business offerings, acts as a reliable tech layer between banks, fintechs, and financial institutions."}
        image={About_img} />
       <CountSection />
       <WhoWeAre />
       <AboutBanner />
       <WhyAccupayd title="Why Accupayd?" />
       {/* <OurTeam title="Leadership Team" content="Lorem ipsum dolor sit amet consectetur. Augue nam quis ut nisl convallis. Faucibus cras platea ut pellentesque gravida et." /> */}
       <Certified />
       <GetinTouch />
    </div>
  )
}

export default About