import React, { useEffect } from 'react'
import PageBanner from '../../../components/page-banner/PageBanner'
import { COPRATECARDSBG } from '../../../assets/images'
import { FeaturedBenefits } from '../../../components/featured-benefits/featured-benefits'
import CommomBanner from '../../../components/commonbanner/CommomBanner'
import { SloveFor } from '../../../components/slovefor/SloveCard'
import FooterServices from '../../../components/Footer-services/FooterServices'
import GetinTouch from '../../../components/get-in-touch/GetinTouch'

const CoporateCard = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <div className='subpage_header'>
    <PageBanner
        breadcrumb={"Card Issuance"}
        breadcrumb2={"Corporate Cards"}
        title={"Corporate Cards"}
        preLink= {'/card-issuance'}
        content={"These services play a crucial role in modern commerce, enabling secure and efficient transactions between individuals, businesses, and other entities. There are various types of payment services available, catering to different needs and preferences. Some common payment services include"}
        image={COPRATECARDSBG} />
    </div>
    <FeaturedBenefits  title="Featured Benefits" content="Corporate cards offer several featured benefits designed to meet the specific needs of businesses and employees. Here are some of the key featured benefits of corporate cards" routeName="copratecard" />
    <CommomBanner title={"Get the Card Issuance for Your Needs"}  />
    <SloveFor   routeName="coporate"  />
    <FooterServices />
    <GetinTouch/>
    </>
  )
}

export default CoporateCard