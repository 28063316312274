import React, { useEffect } from 'react'
import PageBanner from '../../../components/page-banner/PageBanner'
import { FeaturedBenefits } from '../../../components/featured-benefits/featured-benefits'
import CommomBanner from '../../../components/commonbanner/CommomBanner'
import { SloveFor } from '../../../components/slovefor/SloveCard'
import FooterServices from '../../../components/Footer-services/FooterServices'
import GetinTouch from '../../../components/get-in-touch/GetinTouch'
import { VIRTUALCARDBG } from '../../../assets/images'

const VirtualCardsPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
    <div className='subpage_header'>
 <PageBanner
     breadcrumb={"Card Issuance"}
     breadcrumb2={"Virtual Cards"}
     title={"Virtual Cards"}
     preLink= {'/card-issuance'}
     content={"Virtual cards, also known as virtual credit cards or digital cards, are a type of payment method that allows users to make online transactions without using their physical credit or debit cards."}
     image={VIRTUALCARDBG} />
 </div>
 <FeaturedBenefits title="Featured Benefits" content=" While virtual cards offer many benefits, each individual's financial needs and preferences may vary, so it's essential to consider your specific requirements before opting for this payment method." routeName="virtualcard" />
 <CommomBanner title={"Get the Virtual cards for your needs"}  />
 <SloveFor routeName="virtualcard"  />
 <FooterServices />
 <GetinTouch/>
     
   </>
  )
}

export default VirtualCardsPage