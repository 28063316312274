import React, { useEffect } from 'react'
import PageBanner from '../../../components/page-banner/PageBanner';
import { FeaturedBenefits } from '../../../components/featured-benefits/featured-benefits';
import CommomBanner from '../../../components/commonbanner/CommomBanner';
import { SloveFor } from '../../../components/slovefor/SloveCard';
import FooterPaymentsServices from '../../../components/footer-payments-services/FooterPaymentsServices';
import GetinTouch from '../../../components/get-in-touch/GetinTouch';
import { PAYRENTBG } from '../../../assets/images';

const PayRent = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      return (
        <>
        <div className='subpage_header'>
        <PageBanner
            breadcrumb={"Payment Services"}
            breadcrumb2={"Pay Rent"}
            title={"Pay Rent"}
            preLink= {'/payment-services'}
            content={`A "Pay Rent" fintech service is a financial technology solution that allows tenants to make rent payments electronically through digital platforms or mobile applications`}
            image={PAYRENTBG} />
        </div>
        <FeaturedBenefits title="Featured Benefits" content="These services leverage technology to streamline the rent payment process and offer various benefits to both renters and landlords." routeName="payrent" />
        <CommomBanner title={"Get the Pay Rent for your needs"}  />
        <SloveFor   routeName="payrent"  />
        <FooterPaymentsServices />
        <GetinTouch />
        </>
      )
}

export default PayRent