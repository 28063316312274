import React, { useEffect } from 'react'
import PageBanner from '../../../components/page-banner/PageBanner';
import { FeaturedBenefits } from '../../../components/featured-benefits/featured-benefits';
import CommomBanner from '../../../components/commonbanner/CommomBanner';
import { SloveFor } from '../../../components/slovefor/SloveCard';
import FooterPaymentsServices from '../../../components/footer-payments-services/FooterPaymentsServices';
import GetinTouch from '../../../components/get-in-touch/GetinTouch';
import { MOBILEDTHBG } from '../../../assets/images';

const MobileDthRecharge = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      return (
        <>
        <div className='subpage_header'>
        <PageBanner
            breadcrumb={"Payment Services"}
            breadcrumb2={"Mobile and DTH Recharge"}
            title={"Mobile and DTH Recharge"}
            preLink= {'/payment-services'}
            content={`Electronic money transfers refer to the process of sending and receiving money electronically, without the need for physical cash or checks.`}
            image={MOBILEDTHBG} />
        </div>
         <FeaturedBenefits title="Featured Benefits" content="Mobile and DTH (Direct-to-Home) recharge services offer several featured benefits that cater to the convenience and needs of users. " routeName="mobiledth" />
        <CommomBanner title={"Get the Mobile and DTH Recharge for your needs"}  />
        <SloveFor   routeName="mobiledth"  />
        <FooterPaymentsServices />
        <GetinTouch />
        </>
      )
}

export default MobileDthRecharge