import React, { useEffect } from 'react'
import PageBanner from '../../../components/page-banner/PageBanner'
import { FeaturedBenefits } from '../../../components/featured-benefits/featured-benefits'
import CommomBanner from '../../../components/commonbanner/CommomBanner'
import { SloveFor } from '../../../components/slovefor/SloveCard'
import FooterServices from '../../../components/Footer-services/FooterServices'
import GetinTouch from '../../../components/get-in-touch/GetinTouch'
import { GIFTCARDBG } from '../../../assets/images'

const GiftcardPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
    <div className='subpage_header'>
  <PageBanner
      breadcrumb={"Card Issuance"}
      breadcrumb2={"Accupayd - Giftcards"}
      title={"Accupayd - Giftcards"}
      preLink= {'/card-issuance'}
      content={"Accupayd - Giftcards are prepaid cards issued by retailers or businesses, loaded with a specific monetary value, which can be used to make purchases at the respective store or affiliated locations."}
      image={GIFTCARDBG} />
  </div>
  <FeaturedBenefits title="Featured Benefits" content="Accupayd - Giftcards offer several featured benefits for both the recipients and the businesses that issue them." routeName="giftcard" />
  <CommomBanner title={"Get the Accupayd - Giftcards for your needs"}  />
  <SloveFor   routeName="giftcard"  />
  <FooterServices />
  <GetinTouch/>
  </>
  )
}

export default GiftcardPage