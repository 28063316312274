import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { BankingAcc } from '../../utils/CardIssuanceAccord';

const ProductAccordianBank = () => {
  return (
    <div className='page_accord'>
   
    <Accordion >
  

      {
        BankingAcc.map((item, index) =>{
          return(
            <Accordion.Item  key={index} eventKey={index}>
            <Accordion.Header>{item.accrodTitle}</Accordion.Header>
            <Accordion.Body>
              {
                item.accrodContent

              }
              
            </Accordion.Body>
          </Accordion.Item>
          )
        })
      }


       
           
     
      
    </Accordion>
    



      
    </div>
  )
}

export default ProductAccordianBank