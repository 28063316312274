import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { styled } from 'styled-components';
import CountUp from 'react-countup';
export const CountSection = () => {
const countItems = [
{ count: 3, title: 'Years in Service', sufix: '+' },
{ count: 2, title: 'Satisfied Business Clients' , sufix: 'K+'  },
{ count: 3, title: 'Happy Customers',sufix: 'L+' },
{ count: 150, title: 'Transaction per Month', sufix: 'Cr+' },
]
const Section = styled.div`
background-color: #fff3cd;
padding: 80px 0px 65px;
`
const CardSection = styled.div`
background-color: #ffffff;
padding: 30px 20px;
`
const CountText = styled.h1`
color: #1072b8;
font-weight: 700;
font-size: min(max(20px, calc(1.125rem + (48 - 20) * ((100vw - 320px) / (1400 - 320)))), 48px);
font-family: 'Open Sans', sans-serif;
`
const TitleText = styled.h5`
color: #4c4942;
font-weight: 600;
font-size: min(max(14px, calc(0.875rem + ((1vw - 3.2px) * 0.25))), 18px);
font-family: 'Open Sans', sans-serif;
white-space: nowrap;
`
return (
<>
<Section className='count-section mt-110'>
   <Container className=''>
      <Row>
         {
         countItems.map((item, index) => {
         return (
         <Col key={index} xl={3} lg={3} md={6} sm={6} xs={12} className=''>
         <CardSection className='count-card'>
            <CountText>
            <CountUp start={0} end={item.count} duration={7} enableScrollSpy={true} scrollSpyOnce={true}/>
            {item.sufix}
               
               </CountText>
            <TitleText>{item.title}</TitleText>
         </CardSection>
        
         </Col>
         )
         })
         }
      </Row>
   </Container>
</Section>
</>
)
}