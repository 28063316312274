import React, { useEffect } from 'react'
import KickStartForm from '../../components/kickstart-form/KickStartForm'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { styled } from 'styled-components';


const PageTitle = styled.h1`
      font-size: min(max(24px, calc(1.5rem + (60 - 24) * ((100vw - 320px) / (1400 - 320)))), 60px);
       color: rgba(0, 0, 0, 0.85);
       font-family: 'Open Sans', sans-serif;
       font-weight: 800; 
       
`
const PageText = styled.p`
     font-size: min(max(16px, calc(1rem + (20 - 16) * ((100vw - 320px) / (1400 - 320)))), 20px);
       color: #0E0A00BF;
       font-family: 'Open Sans', sans-serif;
       font-weight: 500; 
       margin-top: 20px;
       
`
export const KickStart = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (

    <>
    <Container className='mt-75'>
        <Row>
            <Col lg='6'>
            <div className='kickstart_title'>
                <PageTitle>Let’s make fintech work for you!</PageTitle>
                <PageText>Get in touch with our expert team today</PageText>
                </div>
              </Col>
            <Col lg='6'>
              <div className="form-content">
            <KickStartForm />
            </div>
            </Col>

        </Row>

    </Container>

    <div className='gradident_circle_green left-0'></div>
    <div className='gradident_circle_green top-30'></div>
    <div className='gradident_circle_green top-70'></div>

        

  
    </>
  )
}
