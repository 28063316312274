import React, { useEffect } from 'react'
import PageBanner from '../../components/page-banner/PageBanner'
import { Service_img8 } from '../../assets/images'
import { SloveFor } from '../../components/slovefor/SloveCard'
import GetinTouch from '../../components/get-in-touch/GetinTouch'
import FooterBankingServices from '../../components/footer-banking-services/FooterBankingServices'
import CommomBanner from '../../components/commonbanner/CommomBanner'
import { FeaturedBenefits } from '../../components/featured-benefits/featured-benefits'

const SafeGold = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className='subpage_header'>
      <PageBanner
        breadcrumb2={"Safe Gold"}
        breadcrumb={"Banking"}
        title={"Safe Gold"}
        preLink= {'/banking'}
        content={`"Safe Gold" typically refers to a form of gold investment or ownership that is secure, reliable, and protected against various risks`}
        image={Service_img8} />
      </div>
    
        <FeaturedBenefits title="Featured Benefits" content="Safe gold, also known as digital gold or gold-backed digital assets, refers to a form of investing in gold through digital platforms." routeName="Safe Gold"/>
        <CommomBanner  title={"Get the Safe Gold for Your Needs"}/>
        <SloveFor routeName="Safe Gold" />
        
        <FooterBankingServices />
        <GetinTouch />
        
       
    </div>
  )
}

export default SafeGold