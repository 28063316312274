export const CardIssuanceAccord = [
    { 
         
        accrodTitle: 'Convenience',
        accrodContent : "whether credit, debit, or identification cards, offer unmatched convenience for various transactions. Instead of carrying large amounts of cash, individuals can use cards to make purchases, pay bills, and access services quickly and efficiently."
       
    },
    {
        accrodTitle: 'Security',
        accrodContent : "whether credit, debit, or identification cards, offer unmatched convenience for various transactions. Instead of carrying large amounts of cash, individuals can use cards to make purchases, pay bills, and access services quickly and efficiently."
       
    },
    {
        accrodTitle: 'Access to Credit and Benefits:',
        accrodContent : "whether credit, debit, or identification cards, offer unmatched convenience for various transactions. Instead of carrying large amounts of cash, individuals can use cards to make purchases, pay bills, and access services quickly and efficiently."
       
    },
    {
        accrodTitle: 'Enhanced Tracking and Financial Management',
        accrodContent : "whether credit, debit, or identification cards, offer unmatched convenience for various transactions. Instead of carrying large amounts of cash, individuals can use cards to make purchases, pay bills, and access services quickly and efficiently."
       
    },
    {
        accrodTitle: 'Global Acceptance',
        accrodContent : "whether credit, debit, or identification cards, offer unmatched convenience for various transactions. Instead of carrying large amounts of cash, individuals can use cards to make purchases, pay bills, and access services quickly and efficiently."
       
    },
    {
        accrodTitle: 'Tracking and Budgeting',
        accrodContent : "whether credit, debit, or identification cards, offer unmatched convenience for various transactions. Instead of carrying large amounts of cash, individuals can use cards to make purchases, pay bills, and access services quickly and efficiently."
       
    },
    {
        accrodTitle: 'Access to Credit',
        accrodContent : "whether credit, debit, or identification cards, offer unmatched convenience for various transactions. Instead of carrying large amounts of cash, individuals can use cards to make purchases, pay bills, and access services quickly and efficiently."
       
    },
]

export const BankingAcc = [
  
    {
        accrodTitle: 'Facilitating Transactions',
        accrodContent : "whether credit, debit, or identification cards, offer unmatched convenience for various transactions. Instead of carrying large amounts of cash, individuals can use cards to make purchases, pay bills, and access services quickly and efficiently."
       
    },
    {
        accrodTitle: 'Interest Earnings and Capital Accumulation',
        accrodContent : "Capital accumulation is the growth in wealth through investments or profits. Means to grow wealth can include appreciation, rent, capital gains, and interest. Measuring capital accumulation can be seen through the increased value of assets through investments and savings."
       
    },
    {
        accrodTitle: 'Access to Credit',
        accrodContent : "For the Business owners, it can mean paying employees on time, or capitalizing on a growth opportunity and also means paying rent or buying groceries. Whatever your needs, accessible to credit remain a possibility."
       
    },
    {
        accrodTitle: 'Encouraging Savings',
        accrodContent : "With so many low to middle income households still not saving as much as they would like to, there is a clear need to help people into a better savings position."
       
    },
    {
        accrodTitle: 'Regulation and Stability',
        accrodContent : "We consider how to balance the traditional regulatory objectives of financial stability and consumer protection with the objectives of growth and innovation."
       
    },
    {
        accrodTitle: 'Record Keeping and Transparency',
        accrodContent : "With the improved auditability and compliance, we enabled transparency in our channels to know the state of their records anytime and to gauge their audit readiness."
       
    },
]

export const PaymentAcc = [
    { 
         
        accrodTitle: 'Bill Payment Services',
        accrodContent : "We are providing bill payment services to our customers to make their utility payments like Electricity bill payments, Mobile bill payments, water bill payments, insurance payments etc online through our portal on real-time."
       
    },
    {
        accrodTitle: 'Peer-to-Peer (P2P) Payment Services:',
        accrodContent : "Through Money Transfer facility, we are enabling financial mobility in India through P2P lending. Customers can walk in to any of our merchants shop with their mobile phone to transfer money instantly to any Bank account in India even on Bank holidays or Non-Banking hours."
       
    },
    {
        accrodTitle: 'Mobile Payment Services',
        accrodContent : "Mobile payments which encompass mobile wallets and mobile money transfers are regulated transactions that take place through your mobile device. That is, instead of paying for stuff with cash, cheques, or physical credit cards, mobile payment technology allows you to do so digitally."
       
    },
    {
        accrodTitle: 'Remittance Services',
        accrodContent : "Remittance services attract a diverse range of customers, including migrant workers, expatriates, and individuals who need to send money to family members or friends in other countries. Remittance services allow payment businesses to offer cross-border payment solutions to their customers. This can help to facilitate global trade and commerce, which is becoming increasingly important in today’s globalized economy."
       
    },
    {
        accrodTitle: 'Contactless Payments',
        accrodContent : "Contactless payment technology enables payment transactions via a contactless chip embedded in payment cards, tags, key fobs and mobile phones. The chip communicates with a reader device that uses radio frequency or Near Field Communication (NFC) standards."
       
    },
    {
        accrodTitle: 'Online Payment Gateways',
        accrodContent : "Instant payments made possible by click of a link. Customers can collect payments from anywhere in India. There is No Coding, App or website required."
       
    },
    {
        accrodTitle: 'Credit Card Processing',
        accrodContent : "Our service that enables merchants and business owners to accept payments from customers who are using payment methods other than cash or check. We navigates the interface between the merchants bank and the customers."
       
    },
]