import { styled } from 'styled-components';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { hover } from '@testing-library/user-event/dist/hover';


const CardText = styled.p`
font-size: min(max(13px, calc(0.8125rem + (16 - 13) * ((100vw - 320px) / (1400 - 320)))), 16px);
 color: #0e0a00ac;
 font-family: 'Open Sans', sans-serif;
 font-weight: 600; 
 line-height: 28px;
 @media screen and (max-width:991px) {
   line-height: 22px;
   
 }
 `
 const CardTitle = styled.h3`
 font-size: min(max(18px, calc(1.125rem + (24 - 18) * ((100vw - 320px) / (1400 - 320)))), 24px);
color: #0e0a00;
font-family: 'Open Sans', sans-serif;
font-weight: 800; 
`
const CommonCard = ({ cardtitle, content, image, bg, cardlink }) => {

    const CarsStyle = {

        position: "relative",
        height: "405px",
        width: "100%",
        boxShadow: "4px 4px 16px 0px rgb(242, 242, 242)",
        overflow: "hidden",
        padding: "22px",
    
        border: "2px solid #f5f5f5",

      

    }
   return (
    <>
    <Card className='common_card' style={CarsStyle}>
       <div className='top_section'>
          <CardText>
           {content} 
              <Link to={cardlink} className='f-bold'>
             Know More</Link> 
          </CardText>
       </div>
       <div className='bottom_section'>
          <div className='d-flex align-items-center  justy-between    position-relative z-2'>
             <CardTitle>{cardtitle}</CardTitle>
             <img className='img-fluid card_img_sm' src={image} alt="pic" />
          </div>
       </div>
       <div className='rouded_corner' style={{ background: bg }}>
       </div>
    </Card>
    </>
    )
}

export default CommonCard