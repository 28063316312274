import React, { useEffect } from 'react'
import PageBanner from '../../components/page-banner/PageBanner'
import { Service_img } from '../../assets/images'
import { SloveFor } from '../../components/slovefor/SloveCard'
import { WhyAccupayd } from '../../components/why-accupayd/whyAccupayd'
import GetinTouch from '../../components/get-in-touch/GetinTouch'
import CardIssunaceAccord from '../../components/card-issuance-accordian/CardIssunaceAccord'
import CommomBanner from '../../components/commonbanner/CommomBanner'
import FooterServices from '../../components/Footer-services/FooterServices'
import { SecureCard } from '../../components/secure-card-issuance/secure-card-issuance'



const CardIssuance = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <PageBanner
        breadcrumb={"Card Issuance"}
        title={"Card Issuance"}
        preLink= {'/card-issuance'}
        content={"Card issuance refers to the process of creating and distributing physical or virtual cards to individuals or organizations for various purposes. These cards can be credit cards, debit cards, identification cards, access cards, accupayd - Giftcards, loyalty cards, and more."}
        image={Service_img} />
        <CardIssunaceAccord />
        <SecureCard/>
        <SloveFor title={'We Solve For'} routeName="Card Issuance" content = "Industries across the board use our card solutions for diverse requirements. You name it, we've powered it!" />
        <CommomBanner title={"Get the Card Issuance for Your Needs"} />
        <WhyAccupayd title="Why Accupayd?" />
        <GetinTouch />
       <FooterServices />
       
    </div>
  )
}

export default CardIssuance