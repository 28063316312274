import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { styled } from 'styled-components'
import { Banner_bg, Scroll, Service_img } from '../../assets/images'
import { Col, Container, Row } from 'react-bootstrap'
import { BiChevronRight } from 'react-icons/bi'


const Bannersec = styled.section`
background-image: url(${Banner_bg});
background-size: contain;
padding-top: 40px;
background-repeat: no-repeat;
background-position: right;
@media screen and (max-width:1200px){
   background-image: unset;
}
`
const Breadcrumb = styled.div`
display: flex;
align-items: center;
margin-bottom: 20px;

`
const Bread = styled.p`
  font-size: min(max(11px, calc(0.6875rem + (16 - 11) * ((100vw - 320px) / (1400 - 320)))), 16px);
color: #000;
font-family: 'Open Sans',sans-serif;
opacity: 50%;
font-weight: 600;
margin: 0;

@media screen and (max-width:540px) {
    font-size:12px !important;
    
}
@media screen and (min-width:991px) and (max-width:1280px) {
    font-size:11px !important;
}

`
const Activebread = styled.p`
 font-size: min(max(11px, calc(0.6875rem + (16 - 11) * ((100vw - 320px) / (1400 - 320)))), 16px);
color: #000;
font-family: 'Open Sans',sans-serif;
opacity: 75%;
font-weight: 600;
margin: 0;
cursor: pointer;
&:hover{
    opacity: 80% !important;
}
@media screen and (max-width:540px) {
    font-size:12px !important;
    
}
@media screen and (min-width:991px) and (max-width:1280px) {
    font-size:11px !important;
}
`
const Commontitle = styled.h2`
font-size: min(max(14px, calc(0.875rem + (32 - 14) * ((100vw - 320px) / (1400 - 320)))), 32px);
color: #1072B8;
font-family: 'Open Sans',sans-serif;
font-weight: 700;
margin-bottom: 12px;
`

const Maintitle = styled.h1`
font-size: min(max(24px, calc(0.875rem + (50 - 24) * ((100vw - 320px) / (1400 - 320)))), 50px);
color: #0E0A00;
font-family: 'Open Sans',sans-serif;
font-weight: 800;
margin-bottom: 20px;
`
const Content = styled.p`
 font-size: 16px;
color: #000;
font-family: 'Open Sans',sans-serif;
opacity: 75%;
font-weight: 600; 
line-height: 32px;
margin-bottom: 30px;
`
const Startbtn = styled.button`
      background: linear-gradient(90deg, rgba(243,154,52,1) 0%, rgba(252,199,58,1) 50%);
    color: #0E0A00;
    font-family: 'Open Sans',sans-serif;
    font-weight: 700;
    border: none;
    padding: 6px 24px;
    font-size: min(max(14px, calc(0.875rem + (20 - 14) * ((100vw - 320px) / (1400 - 320)))), 20px);
    border-radius: 5px;
    &:hover{
        background: #0E0A00;
        color: #fff;
    }

`


const PageBanner = ({breadcrumb, title, content, image, breadcrumb2, preLink,routeName }) => {

   
    return (
        <>
            <Bannersec>
                <Container>
                    <Row>
                        <Col className='content-section' lg={5} style={{paddingRight: '35px'}}>
                            <Breadcrumb>
                            {
                                routeName == 'about-us' ?  <Bread>Home</Bread> : <Bread>Products</Bread>
                            }
                           
                                
                                <BiChevronRight className='rightarrow' />
                                <div className='d-flex'>
                                    
                                <Activebread> <Link className={breadcrumb2 ? 'link-new ' : 'main-page-link'} to={preLink}>{breadcrumb}</Link> </Activebread>
                                {
                                  breadcrumb2 ? <Activebread className={ breadcrumb2 ? "active_new" : '' }> <BiChevronRight className='rightarrow' /> {breadcrumb2}</Activebread> : ''

                                }

                                </div>

                            </Breadcrumb>
                            {
                                routeName == 'about-us' ?  <Commontitle >About Us</Commontitle> : <Commontitle>Our Product</Commontitle>
                            }
                           
                            

                            <Maintitle>{title}</Maintitle>

                            <Content style={{paddingRight: '140px'}} className="banner-content">{content} </Content>
                            <Link to="/kickstart">
                            <Startbtn>Kickstart</Startbtn>
                            </Link>

                            <div className='mt-4 scroll_down'>
                                <a href="#scroll_section"> <img src={Scroll} alt="scroll" /></a>
                               
                            </div>

                        </Col>
                        <Col className='mobile_center' lg={7}>
                            <img src={image} alt="" className='pl-7 page_banner_img' />
                        </Col>
                    </Row>
                </Container>
            </Bannersec>
            <div className='gradident_circle_green_1'></div>
        </>
    )
}

export default PageBanner