import React, { useState, useRef, useLayoutEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './navigation.css'
import Button from 'react-bootstrap/Button';
import { LOGO } from '../../assets/images';
import { NavDropdown, } from 'react-bootstrap';
import { Link, NavLink, useLocation } from 'react-router-dom';

const Navigation = ({routeName}) => {


 const [classfy, setClassfy]= useState( false)

  const dropref = useRef(null)
  const location = useLocation();

  const [toggle, setToggle] = useState(false)

  const chanagebg = () => {
    if (window.scrollY > 90) {
      setToggle(true)
      console.log('true')
    }
    else if (window.scrollY < 90) {
      setToggle(false)

    }

  }
  useLayoutEffect(()=>{

    window.addEventListener('scroll', chanagebg);
    // console.log(location.pathname == '/card-issuance' || '/banking' || '/payment-services' ? 'true' : 'false')
   

},[])


  
  return (
    <div className='navbar_section'>
      <Navbar expand="lg" className={toggle === true ? 'navbar_section_navbar shadow' : 'navbar_section_navbar'}  >
        <Container className=''>
          <Link to={'/'} className='logo-link'>
            <img src={LOGO} alt="logo" className='logo-img' />
          </Link>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className='nav-collapse'>
            <Nav className="me-auto">

                <NavLink activeClassName='active' className='nav_items' to="/" onClick={()=>{setClassfy(false) }}>Home</NavLink>
              
              <NavDropdown  activeClassName='active' className={classfy === true || location.pathname === '/card-issuance' ||  location.pathname === '/banking' || location.pathname === '/payment-services'  ? 'nav_items active': 'nav_items' } title="Products" id="basic-nav-dropdown">
                <NavDropdown.Item>
                  <NavLink ref={dropref} activeClassName='active' id='newss'    className='nav_items dropitem fiddd' to="/card-issuance"
                  onClick={()=>{setClassfy(true) }}
                  >Card Issuance</NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <NavLink  activeClassName='active' className='nav_items dropitem' to="/banking"  onClick={()=>{setClassfy(true) }} >Banking</NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                 <NavLink  activeClassName='active' className='nav_items dropitem' to="/payment-services"  onClick={()=>{setClassfy(true) }}  >Payment Services</NavLink>
                </NavDropdown.Item>
              </NavDropdown>

              <NavLink className='nav_items' to="/About" onClick={()=>{setClassfy(false) }} >About</NavLink>
              <NavLink className='nav_items disable' to="/" onClick={()=>{setClassfy(false) }}  >Resources</NavLink>

              {/* <NavDropdown className='nav_items' title="Resources" id="basic-nav-dropdown">
                <NavDropdown.Item>
                  <span><Link className='nav_items' to="/card-issuance">Card Issuance</Link></span>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <span><Link className='nav_items' to="/banking">Banking</Link></span>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <span><Link className='nav_items' to="/payment-services">Payment Services</Link></span>
                </NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
            <div>
            <Link to='/kickstart' >
              <Button className='nav_bar_btn' variant="outline-primary" onClick={()=>{setClassfy(false) }}>Kickstart</Button>
              </Link>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
     
    </div>
  )
}

export default Navigation